import { createSlice } from "@reduxjs/toolkit";
import { jvAuthReasonSliceInterface } from "../../interfaces/Interfaces";



interface InitialState {
    data: jvAuthReasonSliceInterface,
    error: string,
    loading: boolean
}

const initialState: InitialState =
{
    data: {} as jvAuthReasonSliceInterface,
    error: '',
    loading: false
}

const jvAuthReasonSlice = createSlice(
    {
        name: 'jvAuthReasonSlice',
        initialState,
        reducers: {
            resetState: (state) => {
                state.data = {} as jvAuthReasonSliceInterface;
                state.error = "";
                state.loading = false;
            },
            updateJVAuthReason(state, action) {
                state.data = action.payload;
            }
        },

    }
);
export const { updateJVAuthReason, resetState: resetJVReasonForAuthStateAction } = jvAuthReasonSlice.actions;
export default jvAuthReasonSlice.reducer;
