import * as React from "react";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { ExpandMore } from "@mui/icons-material";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon className="accordian-arrow-icon" sx={{ fontSize: "0.9rem", color : "white"}} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "#00313C",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const AccordionTab: React.FC<AccordionProps> = ({ title, children }) => {
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [color, setColor] = useState<boolean>();
  const handleAccordianChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleChange = () => {
    setColor(!color);
  };

  return (
    <Accordion
      defaultExpanded={true}
      onChange={handleAccordianChange("Recommendations")}
    >
      <AccordionSummary aria-controls="panel2d-content" id="panel2d-header ">
        <Typography className="text-color">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className="accordian-padding">{children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionTab;
