import React, { useEffect, useState } from 'react';
import ProjectDetails from './ProjectDetail';
import AccordionTab from '../utils/AccordianTab';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import AddIcon from "@mui/icons-material/Add";
import { PropertyProjectDetailState } from '../../interfaces/Interfaces';
import { updatePropertyProjectDetail } from '../../redux/slices/propertyProjectDetailSlice';
import dayjs, { Dayjs } from "dayjs";

const ProjectsDetailSection: React.FC<Record<any, any>> = (props) => {
    const dispatch = useAppDispatch();

    const formData = useAppSelector((state) => { return state.getFormData.formData; });
    const submitErrorModel = useAppSelector((state) => state.submitFormError);
    const budgetedData = useAppSelector((state) => state.budgetedData);
    const nonBudgetedData = useAppSelector((state) => state.nonBudgetedData);
    const improvementSlice = useAppSelector((state) => { return state.commonData.commonData });

    const [projectCount, setProjectCount] = useState(0);
    const [projectDetails, setProjectDetails] = useState<Array<any>>([]);

    useEffect(() => {
        var formDataPropertyDetails: Array<PropertyProjectDetailState> = [];

        if (formData !== null) {
            if (formData?.propertiesDetails === null || formData?.propertiesDetails === undefined) {
                setProjectDetails([]);
                dispatch(updatePropertyProjectDetail({ projectDetails: [] }));
            } else if (formData?.propertiesDetails.length === 0) {
                setProjectDetails([]);
                dispatch(updatePropertyProjectDetail({ projectDetails: [] }));
            } else {
                setProjectCount(formData?.propertiesDetails.length);

                formData?.propertiesDetails.forEach((element: any, i: number) => {
                    formDataPropertyDetails.push({
                        id: i,
                        ...element
                    });
                });

                setProjectDetails(formDataPropertyDetails);
                dispatch(updatePropertyProjectDetail({ projectDetails: formDataPropertyDetails }));
            }
        }
    }, [formData]);

    function handleDataFromChild(id: number, key: any, value: any) {
        const filteredObjectArray = projectDetails.filter(project => project.id == id);



        let myObj = { ...filteredObjectArray[0] };
        myObj[key] = value;
        if (key === "projectManagementFeeNA") {
            myObj.projectManagementFee = 0;
        }

        const replacingFilteredObjectArray: any = projectDetails.filter(project => project.id != id);
        replacingFilteredObjectArray.push(myObj);

        const myData = [].concat(replacingFilteredObjectArray).sort((a: any, b: any) => a.id > b.id ? 1 : -1);

        setProjectDetails(myData);
        dispatch(updatePropertyProjectDetail({ projectDetails: myData }));
    }

    const handleClick = () => {

        var isEmpty = projectDetails.filter(elm => !elm.isDeleted).length === 0;

        var newObject = {
            id: projectCount,
            name: "",
            description: "",
            projectManagementFeeNA: true,
            projectManagementFee: 0,
            dateOfCompletion: dayjs(),
            authorizationAmount: formData?.propertyManagementCompanyName === "OMNI" ? (isEmpty ? nonBudgetedData.data.authorizationAmount : 0) : (improvementSlice.formType ? (isEmpty ? budgetedData.data.authAmount : 0) : (isEmpty ? nonBudgetedData.data.authorizationAmount : 0)),
            isDeleted: false
        }

        var projDetails = [...projectDetails];
        projDetails.push(newObject);
        setProjectDetails(projDetails);
        setProjectCount(projectCount + 1);
        dispatch(updatePropertyProjectDetail({ projectDetails: projDetails }));
    };

    return (
        <AccordionTab title='Property Project Detail' children={<>
            {/* {submitErrorModel.data.authAmountError ? <Grid container xl={12} >
                <Typography variant="h6" style={{ textAlign: "center", width: "100%", color: "red" }}>
                    Amount Sum Error Message
                </Typography>
            </Grid> : null} */}
            <div className="property-grid">
                {projectDetails.map((projectDetail, projectDetailIndex) => {
                    var errorObj = submitErrorModel.data.propertyProjectDetail?.filter((elm: any) => elm.id === projectDetailIndex);
                    var propDetails = [];

                    if (props.formPropertiesDetails?.length > 0) {
                        propDetails = props.formPropertiesDetails;
                    }

                    if (!projectDetail.isDeleted) {
                        return (
                            <ProjectDetails
                                sectionNumber={projectDetailIndex}
                                key={projectDetailIndex}
                                buttonclicked={props.buttonclicked}
                                properties={props.properties}
                                propertyDetails={projectDetail}
                                sendDataToParent={handleDataFromChild}
                                errorObj={errorObj}
                                isEditable={formData?.isFormEditable}
                            />
                        )
                    }
                }
                )}
            </div>
            {formData?.isFormEditable ? <div><Button variant='contained' className="btn-width" disabled={!formData?.isFormEditable} onClick={() => { handleClick(); }}>
                <AddIcon sx={{ mr: "2.5px" }} />
                Add Project</Button> </div> : null}
        </>
        } />
    );
};

export default ProjectsDetailSection;

