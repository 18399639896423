import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const ViewTabs = (props: any) => {
    const initialTabIndex = 0;
    const [value, setValue] = useState(initialTabIndex);

    const handleChange = (event: any, newValue: any) => {
        const viewId = event.target.id;
        props.sendData(viewId);
        setValue(newValue);
    };

    return (
        <>
            <Tabs
                className="tabs-dashbard"
                sx={{
                    ".Mui-selected:hover": {
                        color: `white`,
                    },
                }}
                value={value}
                onChange={handleChange}
                aria-label=""
            >
                {props.tabData?.map((tabTitle: any, index: any) => (
                    <Tab label={tabTitle} id={tabTitle} key={index} />
                ))}
            </Tabs>
        </>
    );
};

export default ViewTabs;
