import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { baseUrl, theme } from '../../../components/config/Config';
import { getApiCall, postApiCall } from '../../../services/ApiHelper';
import { Button, Grid } from '@mui/material';
import AuthorizationTypesDataGrid from './AuthorizationTypesDataGrid';
import AuthorizationTypesDialogBox from './AuthorizationTypesDialogBox';
import { authTypeResponse, capProjectResponse } from '../../../interfaces/Interfaces';

function AuthorizationTypes() {
    const [rowData, setRowData] = useState<any>();
    const [assignedRolesDropdown, setAssignedRolesDropdown] = useState<any>();
    const [templatesDropdown, setTemplatesDropdown] = useState<any>();
    const [type, setType] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [invLoading, setInvLoading] = useState<boolean>(false);
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [gridDropDown, setGridDropDown] = useState<any>({
        templateUsed: [],
        rolesAssignedTo: [],
        CreatedBy: [],
        CreatedOn: [],
        UpdatedBy: [],
        UpdatedOn: []

    });
    const [updateData, setUpdateData] = useState<authTypeResponse>(
        {
            id: null,
            name: "",
            formHeading: "",
            rolesAssignedTo: [""],
            templateUsed: "",
            isActive:true
        });

    useEffect(() => {
        if (!openDialog) {
            setUpdateData({
                id: null,
                name: "",
                formHeading: "",
                rolesAssignedTo: [""],
                templateUsed: "",
                isActive:true
            });
        }
    }, [openDialog]);

    useEffect(() => {
        if (submitClicked) {
            getAuthTypesData();
        }
    }, [submitClicked]);

    useEffect(() => {
        getAuthTypesData();
    }, []);

    const getAuthTypesData = async () => {
        var url = `${baseUrl}AuthorizationTypes/details`;

        try {
            setLoading(true)
            const res = await getApiCall(url).then((response: any) => {

                const formattedRows = response?.authTypes?.map((row: any) => ({
                    ...row,
                    isActive: row?.isActive ? "Yes" : "No"
                }));

                setRowData(formattedRows);

                setGridDropDown({
                    templateUsed: response.template,
                    rolesAssignedTo: Object.values(response.assignedRoles),
                    createdBy: response.createdBy,
                    createdOn: response.createdOn,
                    updatedBy: response.updatedBy,
                    updatedAt: response.updatedAt
                });

                setAssignedRolesDropdown(Object.values(response.assignedRoles));
                setTemplatesDropdown(response.template);

                setLoading(false);
            });

        } catch (error) {
            setLoading(false);
        }
    }

    const addProjectdropdown = async () => {
        setSubmitClicked(false);
        setOpenDialog(true)
        setLoading(false);
    }

    return (

        <ThemeProvider theme={theme}>
            <div className="wrapper">
                <Grid item lg={4}>
                    <h2 className="text-left mstitle" >Authorization Types</h2>
                </Grid>
                <AuthorizationTypesDataGrid rowdata={rowData} loading={loading}
                    addProjectdropdown={addProjectdropdown}
                    gridDropDown={gridDropDown} setOpen={setOpenDialog} updateRow={setUpdateData} submitClicked={submitClicked} type={setType} />
            </div>
            {openDialog ? <AuthorizationTypesDialogBox open={openDialog} setOpen={setOpenDialog}
                assignedRolesDropdown={assignedRolesDropdown}
                templatesDropdown={templatesDropdown}
                updateRow={updateData} submitClicked={setSubmitClicked} invLoading={invLoading} rowdata={rowData} type={type} /> : null}
        </ThemeProvider>

    );
}

export default AuthorizationTypes;