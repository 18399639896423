import { GridColTypeDef } from "@mui/x-data-grid-pro";

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0, 
  minimumFractionDigits: 0,
});

const usdPrice: GridColTypeDef = {
  type: 'number',
  width: 130,
  minWidth: 130,
  valueFormatter: (value) => currencyFormatter.format(value),
  cellClassName: 'font-tabular-nums',
};

export const AuthorizationTypesViewsColumn: any[] = [
  {
    field: "name",
    headerName: "Authorization Name",
    type: "string",
    flex: 0.25,
    headerAlign: "left",
    align: "left",
    minWidth: 150
  },
  {
    field: "formHeading",
    headerName: "Authorization Header",
    type: "string",
    flex: 0.25,
    minWidth: 160
  },
  {
    field: "rolesAssignedTo",
    headerName: "Assigned Role",
    type: "string",
    flex: 0.5,
    minWidth: 140
  },
  {
    field: "templateUsed",
    headerName: "Template",
    type: "string",
    flex: 0.25,
    minWidth: 120
  },
  {
    field: "isActive",
    headerName: "Is Active",
    type: "string",
    flex: 0.25,
    minWidth: 100
  },
  {
    field: "createdBy",
    headerName: "Created By",
    type: "string",
    flex: 0.25,
    minWidth: 120
  },
  {
    field: "createdOn",
    headerName: "Creation Date",
    type: "string",
    flex: 0.25,
    minWidth: 130
  },
  {
    field: "updatedBy",
    headerName: "Updated By",
    type: "string",
    flex: 0.25,
    minWidth: 120
  },
  {
    field: "updatedAt",
    headerName: "Updated Date",
    type: "string",
    flex: 0.25,
    minWidth: 130
  }
];
