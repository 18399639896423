import { Autocomplete, Box, Button, Checkbox, Grid, TextField } from "@mui/material";
import { DataGridPro, GridCellParams, GridColDef, GridHeaderFilterCellProps } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { AuthorizationTypesViewsColumn } from "./AuthorizationTypesViewsColumn";
import { toUpper } from "lodash";

import * as XLSX from 'xlsx';
import AddIcon from "@mui/icons-material/Add";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import dayjs, { Dayjs } from 'dayjs';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AuthorizationTypesDataGrid = (props: any) => {

    const [rowData, setRowData] = useState<any>();
    const [headingFilters, setHeadingFilters] = useState<any>({});
    const [selectedOptions, setSelectedOptions] = useState<any>({});
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 25,
        page: 0,
    });

    useEffect(() => {
        setRowData(props.rowdata);
        setHeadingFilters(props.gridDropDown);
    }, [props.rowdata]);

    const exportExcel = () => {
        const data = rowData.map((row: any) =>
            AuthorizationTypesViewsColumn.map(col => row[col.field] || '')
        );
        const worksheet: any = XLSX.utils.aoa_to_sheet([
            AuthorizationTypesViewsColumn.map(col => col.headerName),
            ...data,
        ]);
        
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'DataGrid');
        const now = dayjs();
        const hour = now.format('HH');
        const minute = now.format('mm');
        const day = now.format('DD');
        const month = now.format('MM');
        const year = now.format('YYYY');
        const seconds = now.format('ss')
        const timestamp = `${day}${month}${year}${hour}${minute}${seconds}`;
        XLSX.writeFile(workbook, `authorization_types_${timestamp}.xlsx`);
    };

    const customTextHeaderFilter = (params: GridHeaderFilterCellProps) => {
        return (
            <TextField
                id="standard-basic"
                variant="standard"
                key={props.selectedTab || props.isActive}
                onChange={(event) => {
                    handleFilterChange(event.target.value, params.item.field);
                }}
            />
        );
    };

    const dropDownFiltering = (value: Array<string>, field: string, filteredRows: any) => {
        var filteredDropdownData: any = [];

        if (field === "rolesAssignedTo") {
            value.forEach((selectedDropdown: string) => {
                var filteredData = filteredRows.filter((fundRow: any) => fundRow[field] !== undefined && (toUpper(fundRow[field]).includes(toUpper(selectedDropdown))));

                filteredData.forEach((perFilterd: any) => {
                    var isIncluded = false;
                    filteredDropdownData.forEach((element: any) => {
                        if (element.id === perFilterd.id) {
                            isIncluded = true;
                        }
                    });

                    if (!isIncluded) {
                        filteredDropdownData.push(perFilterd);
                    }

                });
            });
        } else {
            value.forEach((selectedDropdown: string) => {
                var filteredData = filteredRows.filter((fundRow: any) => fundRow[field] !== undefined && (toUpper(fundRow[field]) === toUpper(selectedDropdown)));

                filteredData.forEach((perFilterd: any) => {
                    filteredDropdownData.push(perFilterd);
                });
            });
        }

        return filteredDropdownData;
    }

    const handleFilterChange = (e: any, field: string) => {
        var value = e;
        var fieldName = field;
        var filterModel = {
            items: [],
        }
        setSelectedOptions({
            ...selectedOptions,
            ...{
                [fieldName]: value,
            },
        });
        filterModel.items = {
            ...selectedOptions,
            ...{
                [fieldName]: value,
            },
        };
        filterData(filterModel);
    };

    const filterData = (filterModel: any) => {
        var filteredRowData: any = props.rowdata;

        for (const key of Object.keys(filterModel.items)) {
            switch (typeof (filterModel.items[key])) {
                case "object":
                    console.log(key);

                    if (filterModel.items[key].length !== 0) {
                        filteredRowData = dropDownFiltering(filterModel.items[key], key, filteredRowData);
                    }
                    break;
                case "string":
                    if (filterModel.items[key].length !== 0) {
                        filteredRowData = filteredRowData?.filter((fundRow: any) => fundRow[key] !== undefined && toUpper(fundRow[key]).includes(toUpper(filterModel.items[key])));
                    }
                    break;
                default:
                    break;
            }
        }
        setRowData(filteredRowData);
    }

    const customDropDownHeaderFilter = (params: GridHeaderFilterCellProps) => {

        var columnFilterList = headingFilters[params.item.field] || [];

        return (
            <Autocomplete
                className="autocomplete-root"
                ListboxProps={{
                    className: "autocomplete-dashboard"
                  }}
                multiple
                limitTags={1}
                key={props.submitClicked}
                id="tags-standard"
                onChange={(event, newInputValue) =>
                    handleFilterChange(newInputValue, params.item.field)
                }
                options={columnFilterList}
                disableCloseOnSelect
                renderOption={(props, option: any, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                        />
                        {option}
                    </li>
                )}
                fullWidth
                renderInput={(params) => <TextField {...params} variant="standard" />}
            />
        );
    };

    const columns: any = AuthorizationTypesViewsColumn.map((colDef: any) => {

        let { valueFormatter, ...rest } = colDef;
        if (
            colDef.field === "templateUsed" ||
            colDef.field === "rolesAssignedTo" ||
            colDef.field === "createdBy" ||
            colDef.field === "createdOn" ||
            colDef.field === "updatedBy" ||
            colDef.field === "updatedAt"
        ) {
            return {
                ...rest,
                renderHeaderFilter: customDropDownHeaderFilter,
            };
        }
        else if (colDef.field === "name") {
            return {
                ...rest,
                renderHeaderFilter: customTextHeaderFilter,
                cellClassName: (params: GridCellParams<any, number>) => {
                    return "project-name-cell";
                },
            };
        }
        else {
            return {
                ...rest,
                ...valueFormatter,
                renderHeaderFilter: customTextHeaderFilter,
            };
        }
    });

    const handleCellClick = (params: any) => {
        if (params.field === "name") {
            props.type("Edit");
            props.addProjectdropdown()
            var assignedrolesArray = params.row.rolesAssignedTo.split(", ");

            props.updateRow({
                id: params.row.id,
                name: params.row.name,
                formHeading: params.row.formHeading,
                rolesAssignedTo: assignedrolesArray,
                templateUsed: params.row.templateUsed,
                isActive: params.row.isActive === "Yes"
            })
        }
    }

    return (
        <>
            <Grid
                container
                justifyContent="flex-end"
                spacing={2}
                sx={{ mb: 2 }}
            >
                <Grid item>
                    <Button
                        variant="contained"
                        className="primary"
                        onClick={() => { props.addProjectdropdown(); props.type("Add") }}
                    >
                        <AddIcon sx={{ mr: 1 }} />
                        Add
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        className="primary"
                        onClick={exportExcel}
                    >
                        <FileDownloadOutlinedIcon sx={{ mr: 1 }} />
                        EXCEL
                    </Button>
                </Grid>
            </Grid>
            <Box className="dashboard-table-wrapper">
                <div style={{ width: "100%" }}>
                    <DataGridPro
                        className="dashboard-dataGrid"
                        getRowId={(row) => row.id}
                        rows={rowData ?? []}
                        columns={columns}
                        headerFilters
                        columnHeaderHeight={48}
                        headerFilterHeight={48}
                        pageSizeOptions={[25, 50, 100, 200]}
                        rowHeight={40}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        loading={props.loading}
                        pagination
                        disableColumnMenu={true}
                        autoHeight
                        onCellClick={handleCellClick}
                        hideFooter={false}
                    />
                </div>
            </Box>
        </>
    );
};

export default AuthorizationTypesDataGrid;
