import React, { useEffect, useState } from "react";
import Approversdatagrid from "./Approversdatagrid";
import { ThemeProvider } from "styled-components";
import { baseUrl, theme } from "../../../components/config/Config";
import { getApiCall, postApiCall } from "../../../services/ApiHelper";

import ApproversDialogBox from "./ApproversDialogBox";
import { Button, Grid } from "@mui/material";
import { approverresponse } from "../../../interfaces/Interfaces";

import { verbiageProjectResponse } from "../../../interfaces/Interfaces";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { drop } from "lodash";
const Approvers = () => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [rowData, setRowData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [approverlevel, setapproverlevel] = useState<any>();
  const [approvers, setapprovers] = useState<any>();
  const [type, setType] = useState<string>("");
  const [fund, setFundData] = useState<any>();
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [gridDropDown, setGridDropDown] = useState<any>({
    approverLevel: [],
    approverName: [],
    approverEmail: [],
    fund: [],
    CreatedBy: [],
    CreatedOn: [],
    UpdatedBy: [],
    UpdatedAt: [],
  });
  const [approverdropdown, setApproverdropdown] = useState<any>({
    approverLevel: [],
    approverName: [],
    fund: [],
  });

  const [approvernamedropdown, setapprovernamedropdown] = useState<any>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [updateData, setUpdateData] = useState<approverresponse>({
    id: null,
    approver: {
      UserName: "",
      Email: "",
    },
    fund: [],
    approverLevel: "",
    isActive: true,
  });

  useEffect(() => {
    if (submitClicked) {
      getApproverData();
    }
  }, [submitClicked]);

  useEffect(() => {
    getApproverData();
  }, []);

  useEffect(() => {
    if (!openDialog) {
      setUpdateData({
        id: null,
        approver: {
          UserName: "",
          Email: "",
        },

        fund: [],
        approverLevel: "",
        isActive: true,
      });
    }
  }, [openDialog]);

  const getApproverData = async () => {
    var url = `${baseUrl}Approvers/allUser`;
    try {
      setLoading(true);
      const res = await getApiCall(url).then((response: any) => {
        const formattedRows = response?.data?.map((row: any) => ({
          ...row,
          isActive: row?.isActive ? "Yes" : "No",
        }));
        setRowData(formattedRows);

        setGridDropDown({
          approverLevel: response?.approverLevel,
          approverName: response?.approverName,
          authName: response?.authName,
          Name: response?.approverName,
          approverEmail: response?.approverEmail,
          fund: response?.fund,
          CreatedBy: response?.createdBy,
          CreatedOn: response?.createdOn,
          UpdatedBy: response?.updatedBy,
          UpdatedAt: response?.updatedAt,
        });
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      console.log("error in fetching funds", error);
    }
  };
  const addApproverdropdown = async () => {
    setSubmitClicked(false);
    setOpenDialog(true);
    setLoading(false);
    if (
      approverdropdown.approverLevel?.length === 0 ||
      approverdropdown.approverName?.length === 0
    ) {
      var url = `${baseUrl}Approvers/drpDown`;
      try {
        const res = await getApiCall(url).then((response: any) => {
          setApproverdropdown(response);
        });
      } catch (error) {
        console.log("error in fetching funds", error);
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="wrapper">
        <Grid item lg={4}>
          <h2 className="text-left mstitle">Approvers</h2>
        </Grid>
        {openDialog ? (
          <ApproversDialogBox
            open={openDialog}
            setOpen={setOpenDialog}
            updateRow={updateData}
            approverlevel={approverlevel}
            approverdropdown={approverdropdown}
            approversdata={approvers}
            fundNames={fund}
            submitClicked={setSubmitClicked}
            type={type}
          />
        ) : null}
        <Approversdatagrid
          rowdata={rowData}
          loading={loading}
          gridDropDown={gridDropDown}
          addApproverdropdown={addApproverdropdown}
          open={setOpenDialog}
          setOpen={setOpenDialog}
          type={setType}
          submitClicked={submitClicked}
          updateRow={setUpdateData}
        />
      </div>
    </ThemeProvider>
  );
};
export default Approvers;
