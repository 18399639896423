import { NavLink } from "react-router-dom";

const Dropdown = (props: any) => {
    return (
        <ul onMouseLeave={props.hoverEnd} onMouseEnter={props.hoverStart}>
            {props.submenus.map((submenu: any, index: number) => (
                <li key={index} className="menu-items">
                    <NavLink to={submenu.url}>{submenu.title}</NavLink>
                </li>
            ))}
        </ul>
    );
};

export default Dropdown;