import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CapitalAuthorization from "../pages/CapitalAuthorizations";
import LeaseAuthorization from "../pages/LeaseAuthorization";
import ModificationAuthorization from "../pages/ModificationAuthorization";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { LeasingandPropertyManagement } from "../pages/LeasingandPropertyManagement";
import { getFormData, getFormDataById } from "../redux/slices/getFormDataSlice";
import { CircularProgress } from "@mui/material";
import { baseUrl } from "../components/config/Config";
import { getApiCall, postApiCall } from "../services/ApiHelper";
const ConditionalRoute = () => {
  const { id } = useParams();
  const [routing, setRouting] = useState<any>();
  const parentComp :any = {
    CaptialAuthorization: CapitalAuthorization,
    LeaseAuth: LeaseAuthorization,
    AppointmentLCNPMC: LeasingandPropertyManagement,
    Modifications: ModificationAuthorization
  };
  
  var url = `${baseUrl}Authorizations/componentname?id=${id}`;
  const handelChange = async() =>{
    const comp = await getApiCall(url);
    setRouting(comp)
  } 
  useEffect(() => {
    if (id) {
      handelChange()
    }
  }, [id]);
  let Component = parentComp[routing?.ParentComponent]
  return(
    <>
   {routing ?  
  <Component /> : <div  className="loading-backdrop loading-backdrop-full">
  <CircularProgress />
</div>}
  </>
)
}
export default ConditionalRoute