import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      <p className="text-center footer-font-size nm">© 2024 Nuveen, LLC. All rights reserved.</p>
    </footer>
  );
}
export default Footer;
