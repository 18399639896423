import { GridColTypeDef } from "@mui/x-data-grid-pro";

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0, 
  minimumFractionDigits: 0,
});

const usdPrice: GridColTypeDef = {
  type: 'number',
  width: 130,
  minWidth: 130,
  valueFormatter: (value) => currencyFormatter.format(value),
  cellClassName: 'font-tabular-nums',
};

export const administrationColumns: any[] = [
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
    },
    {
      field: "fundName",
      headerName: "Fund",
      flex: 0.5,
      minWidth: 120,
    },
    {
      field: "invID",
      headerName: "Investment ID",
      minWidth: 120,
    },
    {
      field: "invName",
      headerName: "Investment Name",
      flex: 0.5,
      minWidth: 150,
    },
    {
      field: "authType",
      headerName: "Authorization Type",
      flex: 0.4,
      minWidth: 140,
    },
    {
      field: "authAmount",
      headerName: "Amount",
      ...usdPrice
    },
    {
      field: "assetMannager",
      headerName: "Asset Manager",
      flex: 0.5,
      minWidth: 130,
    },
    {
      field: "authId",
      headerName: "Auth ID",
      minWidth: 100,
    },
    {
      field: "approver1Name",
      headerName: "Approver 1 Name",
      flex: 0.4,
      minWidth: 140,
    },
    {
      field: "approver1Level",
      headerName: "Approver 1 Level",
      flex: 0.4,
      minWidth: 130,
    },
    {
      field: "approver2Name",
      headerName: "Approver 2 Name",
      flex: 0.4,
      minWidth: 140,
    },
    {
      field: "approver2Level",
      headerName: "Approver 2 Level",
      flex: 0.4,
      minWidth: 130,
    },
    {
      field: "authDate",
      headerName: "Authorized Date",
      flex: 0.4,
      minWidth: 140,
    }
  ];
