import React, { useEffect, useState } from 'react';
import FundsDataGrid from './FundsDataGrid';
import { ThemeProvider } from 'styled-components';
import { baseUrl, theme } from '../../../components/config/Config';
import { getApiCall, postApiCall } from '../../../services/ApiHelper';
import { Grid } from "@mui/material";

function Fund() {
    const [rowData, setRowData] = useState<any>();
    const [fundNameDropdown, setFundNameDropdown] = useState<any>();
    const [statusDropDown, setStatusDropDown] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        getFundsData();
    }, []);

    const getFundsData = async () => {
        var url = `${baseUrl}funds/details`;

        try {
            setLoading(true)
            const res = await getApiCall(url).then((response: any) => {
                console.log(response , "response")
                setRowData(response.funds);
                setFundNameDropdown(response.fundNames);
                setStatusDropDown(response.status)
                setLoading(false);
            })

        } catch (error) {
            setLoading(false)
            console.log("error in fetching funds", error);
        }
    }

    return (
        <ThemeProvider theme={theme}>

            <div className="wrapper">
                <Grid item lg={4}>
                    <h2 className="text-left mstitle" >Funds</h2>
                </Grid>
                <FundsDataGrid rowdata={rowData} loading={loading} fundNameDropdown={fundNameDropdown} statusDropDown={statusDropDown} />
            </div>
        </ThemeProvider>
    );
}

export default Fund;