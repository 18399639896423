import { GridColTypeDef } from "@mui/x-data-grid-pro";

const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
});

const usdPrice: GridColTypeDef = {
    type: 'number',
    width: 130,
    valueFormatter: (value) => currencyFormatter.format(value),
    cellClassName: 'font-tabular-nums',
};
export const ApproverLevelColumn: any[] = [
    { field: 'authName', headerName: 'Authorization Type', type: 'string', minWidth: 300 },
    { field: 'fundType', headerName: 'Fund Type', type: 'string', minWidth: 120 },
    { field: 'funds', headerName: 'Fund', type: 'string', minWidth: 300 },
    { field: 'meetsBudget', headerName: 'Meets Budget', type: 'string', minWidth: 120 },
    { field: 'isAmtHigherThanValuationPercentage', headerName: 'Inv Valuation Greater Than 20%', type: 'string', minWidth: 250 },
    { field: 'order', headerName: 'Order', type: 'string', minWidth: 80 },
    { field: 'marketRate', headerName: 'Market Rate Rent', type: 'string', minWidth: 140 },
    { field: 'lowerLimit', headerName: 'Amount Lower Limit', type: 'string', minWidth: 150 },
    { field: 'upperLimit', headerName: 'Amount Upper Limit', type: 'string', minWidth: 150 },
    { field: 'termLower', headerName: 'Lease Years Lower Limit', type: 'string', minWidth: 200 },
    { field: 'termUpper', headerName: 'Lease Years Upper Limit', type: 'string', minWidth: 200 },
    { field: 'premiseLower', headerName: 'Lease Square Footage Lower Limit', type: 'string', minWidth: 250 },
    { field: 'premiseUpper', headerName: 'Lease Square Footage Upper Limit', type: 'string', minWidth: 250 },
    { field: 'ecoTerms', headerName: 'Modification EcoTerms', type: 'string', minWidth: 180 },
    { field: 'terminationFee', headerName: 'Prop Term Fee', type: 'string', minWidth: 130 },
    { field: 'terminationTime', headerName: 'Prop Term Time', type: 'string', minWidth: 130 },
    { field: 'approverLevels', headerName: 'Approver Levels', type: 'string', minWidth: 140 },
    { field: 'createdBy', headerName: 'Created By', type: 'string', minWidth: 120 },
    { field: 'createdOn', headerName: 'Creation Date', type: 'string', minWidth: 120 },
    { field: 'updatedBy', headerName: 'Updated By', type: 'string', minWidth: 120 },
    { field: 'updatedAt', headerName: 'Update Date', type: 'string', minWidth: 120 },
    { field: 'isActive', headerName: 'Is Active', type: 'string', minWidth: 120 },
]
