import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField, Autocomplete, Radio, FormControlLabel, RadioGroup, FormGroup, Checkbox, Typography, FormControl } from '@mui/material';
import { baseUrl } from '../../../components/config/Config';
import { postApiCall } from '../../../services/ApiHelper';
import { ToastMessage } from '../../../components/utils/ToastMessage';
import { approverLevelRequired, approverLevelResponse, capProjectValidate, showApproverLevelList, toast } from '../../../interfaces/Interfaces';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { makeStyles } from "tss-react/mui"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const initialResponseData: approverLevelResponse = {
    id: null,
    fundType: "",
    authCode: "",
    approverLevels: [],
    funds: [],
    upperLimit: undefined,
    lowerLimit: undefined,
    meetsBudget: undefined,
    isAmtHigherThanValuationPercentage: undefined,
    termLower: undefined,
    termUpper: undefined,
    premiseLower: undefined,
    premiseUpper: undefined,
    order: undefined,
    ecoTerms: undefined,
    terminationFee: undefined,
    terminationTime: undefined,
    marketRate: undefined,
    isActive : true

};

interface ConfigurableNumberFormatProps extends Omit<NumericFormatProps, 'onChange'> {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    variant: 'integer' | 'decimal' | 'currency';
    decimalScale?: number;
    prefix?: string;
    allowNegative?: boolean;
  }

const ConfigurableNumberFormat  = React.forwardRef<HTMLInputElement, ConfigurableNumberFormatProps>(
    function ConfigurableNumberFormat(props, ref) {
        const { onChange, variant, decimalScale, prefix, allowNegative = false, ...other } = props;
    
        const getVariantProps = () => {
          switch (variant) {
            case 'integer':
              return {
                decimalScale: 0,
                allowNegative,
                isAllowed: (values: any) => {
                  const { floatValue } = values;
                  return floatValue === undefined || Number.isInteger(floatValue);
                },
              };
            case 'decimal':
              return {
                decimalScale: decimalScale || 2,
                allowNegative,
              };
            case 'currency':
              return {
                decimalScale: 2,
                allowNegative,
                prefix: prefix || '$',
                fixedDecimalScale: true,
              };
            default:
              return {};
          }
        };

        return (
            <NumericFormat
              {...other}
              {...getVariantProps()}
              getInputRef={ref}
              onValueChange={(values) => {
                onChange({
                  target: {
                    name: props.name,
                    value: values.value,
                  },
                });
              }}
              thousandSeparator=","
              valueIsNumericString
              allowLeadingZeros={false}
            />
        );
    },
);

const ApproverLevelDialogBox = (props: any) => {
    const [toast, setToast] = useState(false);

    const initialRequiredFields: approverLevelRequired = {
        authTypeRequired: false,
        approverLevelRequired: false,
        fundTypeRequired: false,
        fundNameRequired: false,
        orderRequired: false,
        amtUpperLimitRequired: false,
        amtLowerLimitRequired: false,
        ecoTermsRequired: false,
        terminationFeeRequired: false,
        terminationTimeRequired: false,
    };
    const initialShowFields: showApproverLevelList = {
        fundTypeRequired: false,
        fundNameRequired: false,
        orderRequired: false,
        amtLimitRequired: false,
        ecoTermsRequired: false,
        terminationFeeRequired: false,
        terminationTimeRequired: false,
        showFundType: false,
        showFundName: false,
        showMeetsBudget: false,
        showAmtValuationPer: false,
        showAmtLimit: false,
        showTermsLimit: false,
        showPremisesLimit: false,
        showOrder: false,
        showMarketRate: false,
        showEcoTerms: false,
        showTerminationFee: false,
        showTerminationTime: false
    };
    const [responseData, setResponseData] = useState<approverLevelResponse>(initialResponseData)
    const [requiredFields, setRequiredFields] = useState<approverLevelRequired>(initialRequiredFields)
    const [showFields, setShowFields] = useState<showApproverLevelList>(initialShowFields)


    const [toastMessage, setToastMessage] = useState<toast>({
        message: "",
        severity: "",
    });

    const checkValidation = () => {
        const requiredFields = {
            authTypeRequired: responseData.authCode === "",
            approverLevelRequired: responseData.approverLevels.length === 0,
            fundTypeRequired: showFields.fundTypeRequired !== undefined ? responseData?.fundType?.length === 0 || responseData?.fundType?.length === undefined : false,
            fundNameRequired: showFields.fundNameRequired !== undefined  ? responseData?.funds?.length === 0 || responseData?.funds?.length === undefined : false,
            orderRequired: showFields.orderRequired !== undefined ?responseData?.order?.length === 0 || responseData?.order?.length === undefined : false,
            amtUpperLimitRequired: showFields.amtLimitRequired !== undefined? responseData?.upperLimit === undefined || Number.isNaN(responseData?.upperLimit) : false,
            amtLowerLimitRequired: showFields.amtLimitRequired!== undefined ? responseData?.lowerLimit === undefined || Number.isNaN(responseData?.lowerLimit) : false,
            ecoTermsRequired: false,
            terminationFeeRequired: showFields.terminationFeeRequired !== undefined  ? responseData?.terminationFee?.length === 0 || responseData?.terminationFee?.length === undefined : false,
            terminationTimeRequired: showFields.terminationTimeRequired !== undefined  ?responseData?.terminationTime?.length === 0 || responseData?.terminationTime?.length === undefined : false,
        };
        setRequiredFields(requiredFields);
        return Object.values(requiredFields).some(Boolean);
    };


    const handelSubmitClick = async () => {
        var checkFormValidation = checkValidation()
        if (!checkFormValidation) {
            handleSubmit()
        }
        else {
            setToast(true);
            setToastMessage({
                message: "Please fill in the required field(s)",
                severity: "error",
            });
            setTimeout(() => setToast(false), 4000);
        }

    }
    const handleSubmit = async () => {
        var url = `${baseUrl}ApproverLevels/data`;
        try {
            const res = await postApiCall(url, responseData).then(() => {
            setToast(true);
            setToastMessage({
                message: "Authorizations Approver level added sucessfully",
                severity: "success",
            });
            setTimeout(() => setToast(false), 3000);
            setTimeout(() => props.setOpen(false), 3000);

        });
        props.submitClicked(true)

    } catch (error: any) {
        setToast(true);
        setToastMessage({
            message: `${error.response.data.description} (${error.response.data.code})`,
            severity: "error",
        });
        setTimeout(() => setToast(false), 4000);
    }
}

    useEffect(() => {
        if(props?.addApproverLevelData?.authTypesWithShowFields !== undefined){
            setResponseData(props.updateRow)
            setShowFields({
                ...props?.addApproverLevelData.authTypesWithShowFields[props.updateRow.authCode]
            })
        }
    }, [props.updateRow]);


    const handleAuthTypeChange = (event: any) => {
        if(event !== null){
            setResponseData({ ...initialResponseData, authCode: event[0] })
            setRequiredFields({ ...initialRequiredFields, authTypeRequired: false, approverLevelRequired: false })
            setShowFields({
                ...props?.addApproverLevelData.authTypesWithShowFields[event[0]],
            })
        }
        else{
            setResponseData({ ...initialResponseData, authCode: "" })
            setShowFields(initialShowFields)
        }

    }
    return (
        <>

            <Dialog
                open={props.open}
                onClose={props.onClose}
                maxWidth="md"
                fullWidth
                className="common-dialog-box">
                <DialogTitle id="alert-dialog-title" >
                    {props.type} Authorizations Approver Levels
                </DialogTitle>
                <DialogContent style={{
                    height: 'calc(60vh - 64px)',
                    justifyContent: 'center',
                }}
                    className='form-field-label'>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} >
                            <Grid >
                                <label>Authorization Type</label><span className="mandatory"> * </span>
                            </Grid>
                            <Grid>
                                <Autocomplete
                                    id="invId"
                                    loading={props.approverDataLoading}
                                    options={!props.approverDataLoading ? Object.entries(props?.addApproverLevelData.authTypes) : []}
                                    size="small"
                                    fullWidth
                                    value={responseData.authCode ? [responseData.authCode, props?.addApproverLevelData.authTypes[responseData.authCode]] : null}
                                    getOptionLabel={([code, description]) => `${description}`}
                                    isOptionEqualToValue={(option, value) => option[0] === value[0]}
                                    onChange={(event: any, newValue: any) => {
                                        setRequiredFields({ ...requiredFields, authTypeRequired: false })
                                        handleAuthTypeChange(newValue)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            error={requiredFields.authTypeRequired}
                                            {...params}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid>
                                <label>Approver Levels</label><span className="mandatory"> * </span>
                            </Grid>
                            <Grid>
                                <Autocomplete
                                    id="invId"
                                    multiple
                                    limitTags={1}
                                    loading={props.approverDataLoading}
                                    options={!props.approverDataLoading ? props?.addApproverLevelData.approverLevel : []}
                                    value={responseData.approverLevels ?? []}
                                    size="small"
                                    fullWidth
                                    disableCloseOnSelect
                                    onChange={(event, newInputValue: any[]) => {
                                        setResponseData({ ...responseData, approverLevels: newInputValue })
                                        setRequiredFields({ ...requiredFields, approverLevelRequired: false })
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            error={requiredFields.approverLevelRequired}
                                            {...params}
                                        />
                                    )}
                                    renderOption={(props, option: any, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                checked={selected}
                                                disabled={true}
                                            ></Checkbox>
                                            {option}
                                        </li>
                                    )}
                                />
                            </Grid>
                        </Grid>
                        {showFields.showFundType &&
                            <Grid item xs={12} md={6}>
                                <Grid>
                                    <label>Fund Type</label><span className="mandatory"> * </span>
                                </Grid>
                                <Grid>
                                    <Autocomplete
                                        id="invId"
                                        loading={props.approverDataLoading}
                                        options={!props.approverDataLoading ? props?.addApproverLevelData.fundTypes : []}
                                        size="small"
                                        fullWidth
                                        value={responseData.fundType ?? ""}
                                        onChange={(event, newInputValue: any) => {
                                            setResponseData({ ...responseData, fundType: newInputValue })
                                            setRequiredFields({ ...requiredFields, fundTypeRequired: false })
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                error={requiredFields.fundTypeRequired}
                                                {...params}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        }
                        {showFields.showFundName &&
                            <Grid item xs={12} md={6}>
                                <Grid>
                                    <label>Fund</label><span className="mandatory"> * </span>
                                </Grid>
                                <Grid>
                                    <Autocomplete
                                        multiple
                                        disableCloseOnSelect
                                        limitTags={1}
                                        loading={props.approverDataLoading}
                                        options={!props.approverDataLoading ? props?.addApproverLevelData.fund : []}
                                        size="small"
                                        fullWidth
                                        value={responseData.funds ?? []}
                                        onChange={(event, newInputValue: any) => {
                                            setResponseData({ ...responseData, funds: newInputValue })
                                            setRequiredFields({ ...requiredFields, fundNameRequired: false })
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                error={requiredFields.fundNameRequired}
                                                {...params}
                                            />
                                        )}
                                        renderOption={(props, option: any, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    checked={selected}
                                                    disabled={true}
                                                ></Checkbox>
                                                {option}
                                            </li>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        }

                        {showFields.showOrder &&
                            <Grid item xs={12} md={6}>
                                <Grid>
                                    <label>Order</label><span className="mandatory"> * </span>
                                </Grid>
                                <Grid>
                                    <TextField size='small'
                                        fullWidth
                                        error={requiredFields.orderRequired}
                                        value={responseData?.order}
                                        onChange={(event) => { setResponseData({ ...responseData, order: event.target.value }); setRequiredFields({ ...requiredFields, orderRequired: false }) }}></TextField>
                                </Grid>
                            </Grid>
                        }
                        {showFields.showMarketRate &&
                            <Grid item xs={12} md={6}>
                                <Grid>
                                    <label>Market Rate Rent</label>
                                </Grid>
                                <Grid>
                                    <FormControlLabel
                                        control={<Checkbox checked={responseData?.marketRate}
                                            onChange={(event) => setResponseData({ ...responseData, marketRate: event.target.checked })} />}
                                        label=""
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                color: '#343434',
                                                lineHeight: '24px'

                                            },
                                        }} />
                                </Grid>
                            </Grid>
                        }
                        {showFields.showAmtLimit &&
                            <>
                                <Grid item xs={12} md={6}>
                                    <Grid>
                                        <label>Amount Lower Limit</label>{showFields.amtLimitRequired && <span className="mandatory"> * </span>}
                                    </Grid>
                                    <Grid>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            InputProps={{
                                                inputComponent: ConfigurableNumberFormat as any,
                                              }}
                                              inputProps={{
                                                name: 'price',
                                                variant: 'currency',
                                                prefix: '$',
                                                style: { textAlign: 'right' },
                                              }}
                                            error={requiredFields.amtLowerLimitRequired}
                                            value={responseData?.lowerLimit}
                                            onChange={(event) => { setResponseData({ ...responseData, lowerLimit: parseFloat(event.target.value) }); setRequiredFields({ ...requiredFields, amtLowerLimitRequired: false }) }}></TextField>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid>
                                        <label>Amount Upper Limit</label>{showFields.amtLimitRequired && <span className="mandatory"> * </span>}
                                    </Grid>
                                    <Grid>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            InputProps={{
                                                inputComponent: ConfigurableNumberFormat as any,
                                              }}
                                              inputProps={{
                                                name: 'price',
                                                variant: 'currency',
                                                prefix: '$',
                                                style: { textAlign: 'right' },
                                              }}
                                            error={requiredFields.amtUpperLimitRequired}
                                            value={responseData?.upperLimit}
                                            onChange={(event) => { setResponseData({ ...responseData, upperLimit: parseFloat(event.target.value) }); setRequiredFields({ ...requiredFields, amtUpperLimitRequired: false }) }}></TextField>
                                    </Grid>
                                </Grid>
                            </>
                        }
                        {showFields.showTermsLimit &&
                            <>
                                <Grid item xs={12} md={6}>
                                    <Grid>
                                        <label>Lease Years Lower Limit</label>
                                    </Grid>
                                    <Grid>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            InputProps={{
                                                inputComponent: ConfigurableNumberFormat as any,
                                              }}
                                              inputProps={{
                                                name: 'amount',
                                                variant: 'decimal',
                                                decimalScale: 2,
                                                style: { textAlign: 'right' },
                                              }}
                                            value={responseData?.termLower}
                                            onChange={(event) => { setResponseData({ ...responseData, termLower: parseFloat(event.target.value) }) }}></TextField>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid>
                                        <label>Lease Years Upper Limit</label>
                                    </Grid>
                                    <Grid>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            InputProps={{
                                                inputComponent: ConfigurableNumberFormat as any,
                                              }}
                                              inputProps={{
                                                name: 'amount',
                                                variant: 'decimal',
                                                decimalScale: 2,
                                                style: { textAlign: 'right' },
                                              }}
                                            value={responseData?.termUpper }
                                            onChange={(event) => { setResponseData({ ...responseData, termUpper: parseFloat(event.target.value) }) }}></TextField>
                                    </Grid>
                                </Grid>
                            </>
                        }
                        {showFields.showPremisesLimit &&
                            <>
                                <Grid item xs={12} md={6}>
                                    <Grid>
                                        <label>Lease Square Footage Lower Limit</label>
                                    </Grid>
                                    <Grid>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            InputProps={{
                                                inputComponent: ConfigurableNumberFormat as any,
                                              }}
                                              inputProps={{
                                                name: 'termUpper',
                                                variant: 'integer',
                                                style: { textAlign: 'right' },
                                              }}
                                            value={responseData?.premiseLower}
                                            onChange={(event) => { setResponseData({ ...responseData, premiseLower: parseFloat(event.target.value) }) }}></TextField>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid>
                                        <label>Lease Square Footage Upper Limit</label>
                                    </Grid>
                                    <Grid>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            InputProps={{
                                                inputComponent: ConfigurableNumberFormat as any,
                                              }}
                                              inputProps={{
                                                name: 'termUpper',
                                                variant: 'integer',
                                                style: { textAlign: 'right' },
                                              }}
                                            value={responseData?.premiseUpper}
                                            onChange={(event) => { setResponseData({ ...responseData, premiseUpper: parseFloat(event.target.value) }) }}></TextField>
                                    </Grid>
                                </Grid>
                            </>}
                        {showFields.showMeetsBudget &&
                            <Grid item xs={12} md={6}>
                                <Grid>
                                    <FormControlLabel
                                        control={<Checkbox checked={responseData?.meetsBudget}
                                            onChange={(event) => setResponseData({ ...responseData, meetsBudget: event.target.checked })} />}
                                        label="Meets Budget"
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                color: '#343434',
                                                lineHeight: '24px'

                                            },
                                        }} />
                                </Grid>
                            </Grid>
                        }
                        {showFields.showAmtValuationPer &&
                            <Grid item xs={12} md={6}>
                                <Grid>
                                    <FormControlLabel
                                        control={<Checkbox checked={responseData?.isAmtHigherThanValuationPercentage}
                                            onChange={(event) => setResponseData({ ...responseData, isAmtHigherThanValuationPercentage: event.target.checked })} />}
                                        label="Inv Valuation Greater Then 20%"
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                color: '#343434',
                                                lineHeight: '24px'

                                            },
                                        }} />
                                </Grid>
                            </Grid>
                        }
                        {showFields.showEcoTerms &&
                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={responseData?.ecoTerms}
                                                onChange={(event) => {
                                                    setResponseData({ ...responseData, ecoTerms: event.target.checked });
                                                    setRequiredFields({ ...requiredFields, ecoTermsRequired: false });
                                                }}
                                                className={requiredFields.ecoTermsRequired ? `lease-Checkbox` : ``}
                                                
                                            />
                                        }
                                        label={
                                            <Typography
                                                component="span"
                                                style={{
                                                    fontWeight: 'bold',
                                                    fontSize: '16px',
                                                    color: '#343434',
                                                    lineHeight: '24px'
                                                }}
                                            >
                                                Modification EcoTerms
                                                <span className="mandatory"> *</span>
                                            </Typography>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        }
                        {showFields.showTerminationFee &&
                            <Grid item xs={12} md={6}>
                                <Grid>
                                    <label>Prop Term Fee</label>{showFields.terminationFeeRequired && <span className="mandatory"> * </span>}
                                </Grid>
                                <Grid>
                                    <Autocomplete
                                        id="invId"
                                        loading={props.approverDataLoading}
                                        options={!props.approverDataLoading ? props?.addApproverLevelData.terminationFee : []}
                                        size="small"
                                        fullWidth
                                        onChange={(event, newValue: any) => {
                                            setResponseData({ ...responseData, terminationFee: newValue })
                                            setRequiredFields({ ...requiredFields, terminationFeeRequired: false })
                                        }}
                                        value={responseData?.terminationFee ?? ""}
                                        renderInput={(params) => (
                                            <TextField
                                                error={requiredFields.terminationFeeRequired}
                                                {...params}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        }
                        {showFields.showTerminationTime &&
                            <Grid item xs={12} md={6}>
                                <Grid>
                                    <label>Prop Term Time</label>{showFields.terminationTimeRequired && <span className="mandatory"> * </span>}
                                </Grid>
                                <Grid>
                                    <Autocomplete
                                        id="invId"
                                        loading={props.approverDataLoading}
                                        options={!props.approverDataLoading ? props?.addApproverLevelData.terminationTime : []}
                                        size="small"
                                        fullWidth
                                        value={responseData?.terminationTime ?? ""}
                                        onChange={(event, newValue: any) => {
                                            setResponseData({ ...responseData, terminationTime: newValue });
                                            setRequiredFields({ ...requiredFields, terminationTimeRequired: false })
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                error={requiredFields.terminationTimeRequired}
                                                {...params}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>

                        }
                        <Grid item xs={12} md={12}>
                        <FormControlLabel
                            control={<Checkbox checked={responseData?.isActive}
                                onChange={(event) => setResponseData({ ...responseData, isActive: event.target.checked })} />}
                            label="Is Active"
                            sx={{
                                '& .MuiFormControlLabel-label': {
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    color: '#343434',
                                    lineHeight: '24px'

                                },
                            }} />
                    </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>

                    <Button onClick={() => { props.setOpen(false) }} color="primary" variant="outlined" className="btn-tertiary">
                        Cancel
                    </Button>
                    <Button onClick={() => { handelSubmitClick() }} className="btn-primary" variant="contained">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            {toast && (
                <ToastMessage
                    message={toastMessage.message}
                    severity={toastMessage.severity}
                />
            )}
        </>

    );
};
export default ApproverLevelDialogBox;