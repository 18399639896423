import "./App.css";
import {
  Configuration,
  IPublicClientApplication,
  InteractionType,
  PublicClientApplication,
} from "@azure/msal-browser";
import CapitalAuthorization from "./pages/CapitalAuthorizations";
import Footer from "./Layout/Footer";

import HeaderRoot from "./Layout/Header/HeaderRoot";
import { LicenseInfo } from "@mui/x-license-pro";
import Verbiage from "./pages/Settings/Verbiagemasterscreen/Verbiage";
import { createBrowserRouter, RouterProvider, Outlet, Navigate, useParams } from "react-router-dom";

import {
  useMsal,
  MsalAuthenticationTemplate,
  MsalProvider,
} from "@azure/msal-react";

import React, { useState, useEffect } from "react";

import { Roles } from "./interfaces/Interfaces";
import { CurrentUser } from "./interfaces/Interfaces";

import useConfig from "./hooks/common-hooks";
import { getApiCall } from "./services/ApiHelper";
import Dashboard from "./pages/Dashboard";
import AuthorizationTypes from "./pages/Settings/AuthorizationTypes/AuthorizationTypes";
import Fund from "./pages/Settings/Funds/Fund";
import Administration from "./pages/Reports/AdministrationOfInv/Administration";
import ActivitySummary from "./pages/Reports/InvestmentActivitySummary/ActivitySummary";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { updateCurrentUser } from "./redux/slices/currentUserSlice";
import { getUserData } from "./redux/slices/userContextDetailSlice";
import { getNewFormData } from "./redux/slices/newFormDataSlice";
import ConditionalRoute from "./routes/ConditionalRouting";
import { LeasingandPropertyManagement } from "./pages/LeasingandPropertyManagement";
import ModificationAuthorization from "./pages/ModificationAuthorization";
import LeaseAuthorization from "./pages/LeaseAuthorization";
import { baseUrl } from "./components/config/Config";
import { getFormData } from "./redux/slices/getFormDataSlice";
import VerbiageMS from "./pages/Settings/Verbiagemasterscreen/Verbiage";
import CapitalProjects from "./pages/Settings/CapitalProjects/CapitalProjects";
import Approvers from "./pages/Settings/Approversmasterscreen/Approvers";
import ApproverLevel from "./pages/Settings/ApproverLevel/ApproverLevel";
import { SessionTimeoutPopup } from "./components/notifications/sessionTimeoutPopup";
import FundsEmail from "./pages/Settings/Funds_email/FundsEmail";
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENCE_KEY as string);

const AuthProvider = (props: any) => {
  const [config] = useConfig();

  if (!config?.msalConfig?.auth) return <>Loading config...</>;

  const configuration: Configuration = { ...config.msalConfig };

  const pca = new PublicClientApplication(configuration);

  return <MsalProvider instance={pca}>{props.children}</MsalProvider>;
};


export const Main = () => {
  const { accounts, instance } = useMsal();
  const [loggedInUserRole, setLoggedInUserRole] = useState<Roles>();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [authId, setAuthId] = useState<string>('');
  const formData = useAppSelector(getFormData);
  const { authorization } = useParams();
  console.log(authorization)
  const AuthData = useAppSelector(getFormData);
  const { id } = useParams();
  



  if (accounts && accounts.length > 0) {
    const account = accounts[0];
    instance.setActiveAccount(account);

    const currentUser: CurrentUser = {
      name: account?.name,
      email: account?.username,
      tenantId: account?.tenantId,
    };
    dispatch(updateCurrentUser(currentUser));
  }


  const signOutClickHandler = () => {
    const logoutRequest = {
      postLogoutRedirectUri: "/",
    };
    instance.logoutRedirect(logoutRequest);
  };

  const iddata = useAppSelector(getNewFormData);
  

  useEffect(() => {
    dispatch(getUserData(accounts[0].username));

    const fetchAccountData = async () => {
      setLoading(true);
      try {
        const response: any = await getApiCall(
          `${baseUrl}Users/UserContextDetails?mailId=${accounts[0].username}`

        ).then((res: any) => {
          setLoggedInUserRole(res);
          console.log(`${id}` , "id")
          setLoading(false);
        });
        return response?.data;
      } catch (error: any) {
        setLoading(false);
        console.error("Error in GET request:", error.message);
      }
    };
    fetchAccountData();
  }, []);

  const router = createBrowserRouter([
    {
      element: (
        <>
        
          <HeaderRoot
            accounts={accounts}
            signOutClickHandler={signOutClickHandler}
            isAdmin={loggedInUserRole?.isAdmin}
            setAuthId={setAuthId}
          />
        </>
      ),
      errorElement: (
        <>
          <h1>Error 404 - Page Not Found</h1>
        </>
      ),
      children: [
        {
          path: "/",
          element: <Navigate to="/dashboard" />,
        },
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/settings/types",
          element: <AuthorizationTypes />,
        },
        {
          path: "/settings/approverlevels",
          element: <ApproverLevel />,
        },
        {
          path: "/settings/verbiage",
          element: <VerbiageMS />,
        },
        {
          path: "/settings/fundsemail",
          element: <FundsEmail />,
        },
        {
          path: "/settings/funds",
          element: <Fund />,
        },
        {
          path: "/settings/approvers",
          element: <Approvers />,
        },
        {
          path: "/settings/approvedCapitalProjects",
          element: <CapitalProjects />,
        },
        {
          path: "/reports/administrationOfInvestments",
          element: <Administration />,
        },
        {
          path: "/reports/investmentActivitySummary",
          element: <ActivitySummary />,
        },
        {
          path: "/form/:id",
          element: <ConditionalRoute />,
        },
      
        {
          path: "*",
          element: <Navigate to="/dashboard" />,
        },
    
      ],
    },
  ]);

  if (accounts && accounts.length > 0) {
    const account = accounts[0];
    instance.setActiveAccount(account);

    const currentUser: CurrentUser = {
      name: account?.name,
      email: account?.username,
      tenantId: account?.tenantId,
    };
  }

  return (
    <div className="main-container">
      {/* <Navigation /> */}
      {/* <SessionTimeoutPopup /> */}
      <React.Fragment>
      <SessionTimeoutPopup />
        {!loading && 

        <RouterProvider router={router} />}
      </React.Fragment>
      <Footer />
    </div>
  );
};

function App() {
  function ErrorComponent(error: any) {
    return <p>An Error Occurred: {error}</p>;
  }

  function LoadingComponent() {
    return <p>Authentication in progress...</p>;
  }

  return (
    <AuthProvider>
      
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}
      >
        <Main />
      </MsalAuthenticationTemplate>
    </AuthProvider>
  );
}

export default App;
