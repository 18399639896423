import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import AuthorizationReport from './AuthorizationReport';

const ViewTabs = (props: any) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [data, setdata] = useState<any>()
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };
  return (
    <Box className="box-margin">
      <Tabs value={selectedTab} onChange={handleChange} aria-label="view tabs">
        <Tab label="Investment Activity Summary" />
        <Tab label="Modifications" />
      </Tabs>
      <Box>
        {selectedTab === 0 && <AuthorizationReport selected={selectedTab} data={props?.data ? props?.data : []} loading = {props.loading} />}

        {selectedTab === 1 && <AuthorizationReport selected={selectedTab} data={props?.data ? props?.moddata : []} loading = {props.loading} />}
      </Box>
    </Box>
  );
};
export default ViewTabs;