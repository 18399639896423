import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import * as React from 'react';
import _debounce from 'lodash/debounce';
import AccordionTab from "../utils/AccordianTab";
import { updateJVAuthReason } from "../../redux/slices/jvAuthReasonSlice";

const JVAuthReason: React.FC<Record<any, any>> = (props) => {
    const dispatch = useAppDispatch();

    const formData = useAppSelector((state) => state.getFormData.formData);
    const jvAuthreasonData = useAppSelector((state) => { return state.jvAuthReason });

    React.useEffect(() => {
        dispatch(updateJVAuthReason({
            jvAgreement: formData?.authAgreement,
            jvSection: formData?.authSection,
            jvDescription: formData?.authDescription
        }));
    }, [formData]);

    const fieldOnChangeHandler = (field: string, event: any) => {

        switch (field) {
            case "agreement":
                dispatch(updateJVAuthReason({ ...jvAuthreasonData.data, jvAgreement: event.target.value }));
                break;
            case "section":
                dispatch(updateJVAuthReason({ ...jvAuthreasonData.data, jvSection: event.target.value }));
                break;
            case "description":
                dispatch(updateJVAuthReason({ ...jvAuthreasonData.data, jvDescription: event.target.value }));
                break;

            default:
                break;
        }
    }

    return (
        <AccordionTab title={'Reason For Authorization'} children={

            <Grid container spacing={3} >
                <Grid item xs={12}>
                    <Grid>
                        <label>Agreement</label>
                    </Grid>
                    <Grid>
                        <TextField
                            value={jvAuthreasonData.data.jvAgreement}
                            onChange={(event) => {
                                fieldOnChangeHandler("agreement", event);
                            }}
                            multiline
                            minRows={3}
                            maxRows={7}
                            size='small'
                            fullWidth={true}
                            disabled={!formData?.isFormEditable}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid>
                        <label>Section</label>
                    </Grid>
                    <Grid>
                        <TextField
                            value={jvAuthreasonData.data.jvSection}
                            onChange={(event) => {
                                fieldOnChangeHandler("section", event);
                            }}
                            multiline
                            minRows={3}
                            maxRows={7}
                            size='small'
                            fullWidth={true}
                            disabled={!formData?.isFormEditable}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid>
                        <label>Description</label>
                    </Grid>
                    <Grid>
                        <TextField

                            value={jvAuthreasonData.data.jvDescription}
                            onChange={(event) => {
                                fieldOnChangeHandler("description", event);
                            }}
                            multiline
                            minRows={3}
                            maxRows={7}
                            size='small'
                            fullWidth={true}
                            disabled={!formData?.isFormEditable}
                        />
                    </Grid>
                </Grid>
            </Grid>
        } />
    );
};


export default JVAuthReason;
