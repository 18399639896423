import { createSlice, PayloadAction } from "@reduxjs/toolkit";
type commonData = {
    authorizationAmountByAm: string,
    formType: boolean,
    leaseTerms: number,
    leasePremises: number,
    leaseamount: number
};

type InitialState = {
    commonData: commonData,
    data: any
}

const initialState: InitialState = {
    commonData: { formType: false } as commonData,
    data : ""
}

const commonSlice = createSlice({
    name: "currentUser",
    initialState,
    reducers: {
        authorizationAmountChangeByAm(state, action) {
            state.commonData = action.payload;
        },
        ecoTermChange(state, action) {
            state.data = action.payload
        }
    },
});

export const { authorizationAmountChangeByAm ,ecoTermChange} = commonSlice.actions;

export default commonSlice.reducer;