import React, { useEffect, useState } from "react";
import PlayArrow from "@mui/icons-material/PlayArrow";
import {
  reportRequest,
  reportRequestInv,
} from "../../../interfaces/Interfaces";
import { baseUrl, theme } from "../../../components/config/Config";
import { postApiCall } from "../../../services/ApiHelper";
import { useAppSelector } from "../../../redux/hooks";
import {
  Autocomplete,
  Button,
  Checkbox,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ViewTabs from "./ViewTabs";
import * as XLSX from "xlsx";
import { datagridcolumns } from "./datagridcolumns";
import { datagridmodigficationcolumns } from "./datagridcolumns";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import IosShare from "@mui/icons-material/IosShare";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { LoadingButton } from "@mui/lab";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const ActivitySummary: React.FC<Record<any, any>> = () => {
  const currentUserEmail: string = useAppSelector(
    (state: { currentUser: { data: { email: any } } }) => {
      return state.currentUser.data.email;
    }
  );
  let date = new Date();
  let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const [periodFrom, setPeriodFrom] = useState(dayjs(firstDay));
  const [periodTo, setPeriodTo] = useState(dayjs(date));
  const [selectedPortfolio, setSelectedPortfolio] = useState<string[]>();
  const [rowData, setRowData] = useState<any>();
  const [rowdatainv, setRowInvdata] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [portfolioDropDown, setPortfoliorDropDown] = useState<string[]>([]);

  const [reportRequest, setReportRequest] = useState<reportRequestInv>({
    mailId: currentUserEmail?.toLowerCase(),
    columnFilters: {
      portfolio: [],
      startDate: periodFrom,
      endDate: periodTo,
    },
    isInitialRequest: true,
  });
  useEffect(() => {
    const data = getReportData();
  }, [currentUserEmail]);

  var url = `${baseUrl}Reports/ActivitySummaryReportsData`;
  var urls = `${baseUrl}Reports/ReportsData`;

  const getReportData = async () => {
    try {
      setLoading(true);
      const res = await postApiCall(url, reportRequest).then(
        (response: any) => {
          response?.activitySummaryReportDropdowns &&
            setPortfoliorDropDown(
              response?.activitySummaryReportDropdowns?.portfolio
            );
          response?.activitySummaryReportDropdowns &&
            setSelectedPortfolio(
              response?.activitySummaryReportDropdowns?.portfolio
            );

          const totalmodRows = {
            uniqueId: 0,
            othertransactions: "Modifications",
          };
          const totalRows = {
            uniqueId: 0,
            invName: "Administration of investments",
            expenditures: response?.administrationTotal,
          };
          if (response.administrationTotal == 0) {
            setRowData("");
          } else {
            setRowData([totalRows, ...response?.administrationOfInvData]);
          }

          if (response?.modificationData?.length == 0) {
            setRowInvdata("");
          } else {
            setRowInvdata([totalmodRows, ...response?.modificationData]);
          }
          setLoading(false);
        }
      );
    } catch (error) {
      setLoading(false);
      console.log("error in restore", error);
    }
  };

  const getReportDataInv = () => {
    reportRequest.columnFilters = {
      portfolio: selectedPortfolio,

      startDate: periodFrom,
      endDate: periodTo,
    };

    portfolioDropDown.length !== 0 || undefined
      ? (reportRequest.isInitialRequest = false)
      : (reportRequest.isInitialRequest = true);
    setReportRequest(reportRequest);
    getReportData();
  };

  const exportExcel = () => {
    const workbook = XLSX.utils.book_new();

    if (rowData?.length > 0) {
      const data = rowData?.map((row: any) =>
        datagridcolumns?.map((col) => row[col.field] || "")
      );
      const worksheet: any = XLSX.utils.aoa_to_sheet([
        datagridcolumns?.map((col) => col.headerName),
        ...data,
      ]);
      for (let i = 2; i <= data.length + 1; i++) {
        worksheet[`F${i}`].z = "$0";
      }
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        "Investment Activity Summary"
      );
    }
    if (rowdatainv?.length > 0) {
      const modificationdata = rowdatainv?.map((row: any) =>
        datagridmodigficationcolumns?.map((col) => row[col.field] || "")
      );
      const modificationworksheet: any = XLSX.utils.aoa_to_sheet([
        datagridmodigficationcolumns?.map((col) => col.headerName),
        ...modificationdata,
      ]);
      XLSX.utils.book_append_sheet(
        workbook,
        modificationworksheet,
        "Modifications"
      );
    }

    if (rowData?.length > 0 || rowdatainv?.length > 0) {
      const now = dayjs();
      const hour = now.format('HH'); // 12-hour format
      const minute = now.format('mm');
      const day = now.format('DD');
      const month = now.format('MM');
      const year = now.format('YYYY');
      const seconds = now.format('ss')
      const timestamp = `${day}${month}${year}${hour}${minute}${seconds}`;
      XLSX.writeFile(workbook, `Investment_activity_summary_report_${timestamp}.xlsx`);
    }
  };

  const exportPdf = () => {
    const doc = new jsPDF();
    doc.setFontSize(12);
    if (rowData?.length > 0) {
      const text =  `Investment Activity Summary (${periodFrom.format(
        "MMM-DD-YYYY"
      )} to ${periodTo.format("MMM-DD-YYYY")})  `
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const textWidth = doc.getTextWidth(text);
      const fontSize = doc.getFontSize();

      const textHeight = fontSize * 0.3528;
      const xPosition = (pageWidth - textWidth) / 2;
       const yPosition = (pageHeight - textHeight) / 2;
      doc.text(text, xPosition , 10)
      let jointext = selectedPortfolio?.join(', ')
    
      doc.text(`${jointext}` , xPosition/2 , 20);
      const formattedRows = rowData?.map((row: any) => ({
        ...row,
        expenditures: new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(row?.expenditures),
      }));
      autoTable(doc, {
        startY: 30,
        columns: datagridcolumns.map((col) => ({
          header: col.headerName,
          datakey: col.field,
        })),
        body: formattedRows.map((row: any) =>
          datagridcolumns.map((col) => row[col.field])
        ),
        headStyles: { fillColor: "#253746" },
        styles: { fontSize: 5 },
        tableWidth: "auto",
        columnStyles: { 5: { halign: "right" } },
        margin: { top: 10, right: 5, left: 5 },
      });
    }
    if (rowdatainv?.length > 0) {
      doc.addPage();
      const text = 
        `Modification of Investments (${periodFrom.format(
          "MMM-DD-YYYY"
        )} to ${periodTo.format("MMM-DD-YYYY")}) `
        const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const textWidth = doc.getTextWidth(text);
      const fontSize = doc.getFontSize();

      const textHeight = fontSize * 0.3528;
        const xPosition = (pageWidth - textWidth) / 2;
        doc.text(text, xPosition , 10)
        let jointext = selectedPortfolio?.join(', ')
      
        doc.text(`${jointext}` , xPosition/2 , 20);
     
      const formattedmodificationRows = rowdatainv?.map((row: any) => ({
        ...row,
      }));
      autoTable(doc, {
        startY: 30,
        columns: datagridmodigficationcolumns.map((col) => ({
          header: col.headerName,
          datakey: col.field,
        })),
        body: formattedmodificationRows.map((row: any) =>
          datagridmodigficationcolumns.map((col) => row[col.field])
        ),
        headStyles: { fillColor: "#253746" },

        styles: { fontSize: 5 },
        tableWidth: "auto",
        columnStyles: { 5: { halign: "left" } },
        margin: { top: 10, right: 5, left: 5 },
      });
    }
    const now = dayjs();
    const hour = now.format('HH'); 
    const minute = now.format('mm');
    const day = now.format('DD');
    const month = now.format('MM');
    const year = now.format('YYYY');
    const seconds = now.format('ss')
    const timestamp = `${day}${month}${year}${hour}${minute}${seconds}`;


   
    if (rowData?.length > 0 || rowdatainv?.length > 0) {
      doc.save(`Investment_activity_summary_report_${timestamp}.pdf` , );
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <div className="wrapper">
        <div className="reports-container">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <label>Select report parameters</label>
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid>
                <label>Period From</label>
              </Grid>
              <Grid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="MMM-DD-YYYY"
                    slotProps={{ textField: { fullWidth: true, size: "small" } }}
                    value={periodFrom}
                    maxDate={periodTo}
                    onChange={(newValue) => {
                      if (newValue !== null) {
                        setPeriodFrom(newValue);
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid>
                <label>Period To</label>
              </Grid>
              <Grid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="MMM-DD-YYYY"
                    slotProps={{ textField: { fullWidth: true, size: "small" } }}
                    value={periodTo}
                    minDate={periodFrom}
                    onChange={(newValue) => {
                      if (newValue !== null) {
                        setPeriodTo(newValue);
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid>
                <label>Portfolio</label>
              </Grid>
              <Grid>
                <Autocomplete
                  multiple
                  fullWidth
                  size="small"
                  id="checkboxes-tags-demo"
                  disableCloseOnSelect
                  options={portfolioDropDown ?? []}
                  value={selectedPortfolio ?? []}
                  limitTags={1}
                  onChange={(event , newInputValue) =>
                    setSelectedPortfolio(newInputValue)
                  }
                  renderOption={(props, option, { selected }) => {
                    const { ...optionProps } = props;
                    return (
                      <li {...optionProps}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    );
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Grid className="reports-grid">
                <LoadingButton
                  variant="contained"
                  loading={loading}
                  className="run-btn-reports"
                  onClick={getReportDataInv}
                >
                  <PlayArrow sx={{ mr: "2.5px", mb:"2.5px" }} />
                  Run
                </LoadingButton>
              </Grid>
            </Grid>
            <Grid item xs={8} className="grid-hide">
              
            </Grid>
            <Grid item xs={12} sm={6} md={1}>
                <Grid className="reports-grid">
                  <Button
                    variant="contained"
                    className="excel-btn-reports"
                    onClick={exportExcel}
                  >
                    <FileDownloadOutlinedIcon sx={{ mr: "2.5px", mb:"2.5px" }} />
                    Excel
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={1}>
                <Grid className="reports-grid">
                  <Button
                    variant="contained"
                    className="btn-reports"
                    onClick={exportPdf}
                  >
                    <FileDownloadOutlinedIcon sx={{ mr: "2.5px", mb:"2.5px" }} />
                    PDF
                  </Button>
                </Grid>
              </Grid>
          </Grid>
        </div>
      
      <ViewTabs data={rowData} moddata={rowdatainv} loading={loading}/>
      </div>
    </ThemeProvider>
  );
};

export default ActivitySummary;
