import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
    data: any,
    error: string,
    loading: boolean
}
const initialState: InitialState =
{
    data: {} as any,
    error: '',
    loading: false
}

const modProjectDetailSlice = createSlice(
    {
        name: 'modProjectDetail',
        initialState,
        reducers: {
            resetState: (state) => {
                state.data = {} as any;
                state.error = "";
                state.loading = false;
            },
            updateModProjectDetail(state, action) {
                state.data = action.payload;
            }
        },

    }
);
export const { updateModProjectDetail, resetState: resetModprojectDetailDataStateAction } = modProjectDetailSlice.actions;
export default modProjectDetailSlice.reducer;
