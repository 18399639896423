import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
    data: any,
    error: string,
    loading: boolean
}
const initialState: InitialState =
{
    data: {} as any,
    error: '',
    loading: false
}

const propertyProjectDetailSlice = createSlice(
    {
        name: 'propertyProjectDetail',
        initialState,
        reducers: {
            resetState: (state) => {
                state.data = {} as any;
                state.error = "";
                state.loading = false;
            },
            updatePropertyProjectDetail(state, action) {
                state.data = action.payload;
            }
        },

    }
);
export const { updatePropertyProjectDetail, resetState: resetprojectPropertyDetailDataStateAction } = propertyProjectDetailSlice.actions;
export default propertyProjectDetailSlice.reducer;
