import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Autocomplete,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { baseUrl } from "../../../components/config/Config";
import { postApiCall } from "../../../services/ApiHelper";
import {
  approverresponse,
} from "../../../interfaces/Interfaces";
import { ToastMessage } from "../../../components/utils/ToastMessage";
import { toast } from "../../../interfaces/Interfaces";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { approverValidate } from "../../../interfaces/Interfaces";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const ApproversDialogBox = (props: any) => {
  const [toast, setToast] = useState(false);
  const [responseData, setResponseData] = useState<approverresponse>({
    id: null,
    fund: [],
    approver: {
      UserName: "",
      Email: "",
    },
    approverLevel: "",
    isActive : true
  });

  const [validateData, setValidateData] = useState<approverValidate>({
    approverLevel: false,
    approverName: false,
    fund: false,
  });
  const [toastMessage, setToastMessage] = useState<toast>({
    message: "",
    severity: "",
  });

  useEffect(() => {
    setResponseData(props.updateRow);
  }, [props.updateRow]);

  const checkValidation = () => {
    setValidateData({
      approverName: responseData?.approver.UserName ==  "" ? true : false,
      approverLevel: responseData?.approverLevel == "" ? true : false,
      fund: responseData?.fund.length == 0 ? true : false,
    });
    return (
      responseData?.approver.UserName == "" ||
      responseData?.fund.length == 0 ||
      responseData?.approverLevel == ""
    );
  };

  const handleSubmitClick = async () => {
    var checkFormValidation = checkValidation();
    if (checkFormValidation !== true) {
      handleSubmit();
    } else {
      setToast(true);
      setToastMessage({
        message: "Please fill in the required field(s)",
        severity: "error",
      });
      setTimeout(() => setToast(false), 4000);
    }
  };

  const handleSubmit = async () => {
    var url = `${baseUrl}Approvers/add`;
    try {
      const payloaddata = {
        id: responseData.id,
        fund: responseData?.fund,
        approverLevel: responseData?.approverLevel,
        approverName: responseData?.approver.UserName,
        approverEmail: responseData?.approver.Email,
        isActive : responseData?.isActive
      };
      const res = await postApiCall(url, payloaddata).then (() =>
    {
      setToast(true);
      setToastMessage({
        message: "Approvers added sucessfully",
        severity: "success",
      });
      setTimeout(() => setToast(false), 4000);
      setTimeout(() => props.setOpen(false), 4000);
    })
      props.submitClicked(true)
    } catch (error: any) {
      setToast(true);
      setToastMessage({
        message: `${error.response?.data?.description} (${error?.response?.data?.code})`,
        severity: "error",
      });
      setTimeout(() => setToast(false), 4000);
    }
  };
  return (
    <>
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="md"
      fullWidth
      className="common-dialog-box"
    >
      <DialogTitle id="alert-dialog-title">{props.type} Approvers</DialogTitle>

      <DialogContent
        style={{
          height: "calc(60vh - 64px)",
          justifyContent: "center",
        }}
        className="form-field-label"
      >
        {/* First two fields on the top */}
        <Grid container spacing={3}>
          <Grid item xs={6} md={6}>
            <Grid>
              <label>Approver Level</label>
              <span className="mandatory"> * </span>
            </Grid>
            <Grid>
              <Autocomplete
                options={props?.approverdropdown?.approverLevel ?? []}
                value={responseData?.approverLevel ?? []}
                onChange={(event, newInputValue) => {
                  setResponseData({
                    ...responseData,
                    approverLevel: newInputValue,
                  });
                  setValidateData({ ...validateData, approverLevel: false });
                }}
                renderInput={(params) => (
                  <TextField
                    error={validateData?.approverLevel}
                    {...params}
                    variant="outlined"
                    fullWidth
                  />
                )}
                size="small"
              />
            </Grid>
          </Grid>
          <Grid item xs={6} md={6}>
            <Grid>
              <label htmlFor="field1">Approver</label>

              <span className="mandatory"> * </span>
            </Grid>
            <Grid>
              <Autocomplete
                options={props?.approverdropdown?.approver ?? []}
                getOptionLabel={(option: any) => option?.UserName ?? []}
                value={responseData?.approver }
                onChange={(event, newInputValue) => {
                  setResponseData({
                    ...responseData,
                    approver: {
                      UserName: newInputValue?.UserName,
                      Email: newInputValue?.Email,
                    },
                  });
                  setValidateData({ ...validateData, approverName: false });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    error={validateData.approverName}
                  />
                )}
                size="small"
              />
            </Grid>
            {/* Bottom field, centered */}
          </Grid>
          <Grid item xs={6} md={6}>
            <label htmlFor="field1">Fund</label>
            <span className="mandatory"> * </span>
            <Autocomplete
              multiple
              options={props?.approverdropdown?.fund ?? []}
              value={responseData?.fund }
              id="checkboxes-tags-demo"
              disableCloseOnSelect
              limitTags={1}
              onChange={(event, newInputValue) => {
                setResponseData({ ...responseData, fund: newInputValue });
                setValidateData({ ...validateData, fund: false });
              }}
              renderOption={(props, option: any, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={selected}
                    disabled={true}
                  ></Checkbox>
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  error={validateData?.fund}
                  {...params}
                  variant="outlined"
                  fullWidth
                />
              )}
              size="small"
            />{" "}
          </Grid>
          <Grid item xs={12} md={12}>
                        <FormControlLabel
                            control={<Checkbox checked={responseData?.isActive}
                                onChange={(event) => setResponseData({ ...responseData, isActive: event.target.checked })} />}
                            label="Is Active"
                            sx={{
                                '& .MuiFormControlLabel-label': {
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    color: '#343434',
                                    lineHeight: '24px'

                                },
                            }} />
                    </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            props.setOpen(false);
          }}
          color="primary"
          variant="outlined"
          className="btn-tertiary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleSubmitClick();
          }}
          className="btn-primary"
          variant="contained"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
          {toast && (
            <ToastMessage
              message={toastMessage.message}
              severity={toastMessage.severity}
            />
          )}
          </>
  );
};
export default ApproversDialogBox;
