import React, { useState } from "react";
import { Avatar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import DialogContentText from "@mui/material/DialogContentText";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItemIcon from "@mui/material/ListItemIcon";


export const UserDetails = (props: any) => {

  function stringAvatar(name: string) {
    let username =
      name.indexOf(" ") > 0
        ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
        : `${name.substring(0, 2)}`;
    username = username.toUpperCase();
    return {
      sx: {
        bgcolor: "#2b8818", 
        fontSize: "16px",
        marginRight: "-10px",
      },
      children: `${username}`,
    };
  }
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openProfileDropdown = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const closeProfileDropdown = () => {
    setAnchorEl(null);
  };

  const handleSignOutOpen = () => {
    setOpen(true);
  };
  const handleSignOutClose = () => {
    setOpen(false);
  };

  if (props.accounts[0]?.name) {
    return (
      <div>
        <IconButton
          data-testid="user-name-button"
          sx={{
            color: "white",
            fontSize: "1rem",
            marginRight: "10px",
            padding: "0px",
            "&:hover": { backgroundColor: "transparent", cursor: "default" },
          }}
          disableRipple
        >
          <Avatar
            style={{
              margin: "10px",
              width: "40px",
              height: "40px",
            }}
            {...stringAvatar(props.accounts[0].name as string)}
          />
          {props.accounts[0].name}
        </IconButton>
        <IconButton
          sx={{
            color: "white",
            fontSize: "1rem",
            marginRight: "10px",
            padding: "0px",
            "&:hover": { backgroundColor: "transparent" },
          }}
          disableRipple
          onClick={openProfileDropdown}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 2.5,
              ml: 2,
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeProfileDropdown}
        >
          <MenuItem onClick={handleSignOutOpen} style={{fontSize:"1.25rem"}}>
            <ListItemIcon>
              <LogoutIcon sx={{ mr: '-5px' }} fontSize="small" />
            </ListItemIcon>
            Sign Out
          </MenuItem>
        </Menu>
        <Dialog open={open} onClose={closeProfileDropdown}>
          <DialogTitle sx={{ m: 0, p: 2 }}>
            Sign Out
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close"
              onClick={handleSignOutClose}
              className="close-btn"
            >
              <CloseIcon sx={{ mt: "-6px" }}  />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {" "}
            <DialogContentText>
              {"Are you sure you want to sign out?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              data-testid="confirm-signout-btn"
              className="primary-outline"
              onClick={() => props.signOutClickHandler()}
              autoFocus
            >
              Yes
            </Button>
            <Button
              data-testid="cancel-signout-btn"
              className="primary-outline"
              autoFocus
              onClick={handleSignOutClose}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    return null;
  }
};
