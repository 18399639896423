import { GridCellParams, GridColDef, GridColTypeDef } from "@mui/x-data-grid-pro";

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

const usdPrice: GridColTypeDef = {
  type: 'number',
  width: 130,
  minWidth: 130,
  valueFormatter: (value) => currencyFormatter.format(value),
  cellClassName: (params: GridCellParams<any, number>) => {
    if (params.row.uniqueId === 0) {
      return 'dv-amount-font';
    }
    return 'font-tabular-nums';
  },
};

export const datagridcolumns: GridColDef[] = [
  {
    field: "invName",
    headerName: "Commitments",
    flex: 0.5,
    minWidth: 180,
    colSpan: (value, row) => {
      if (row.uniqueId === 0) {
        return 5;
      }
      return undefined;
    },
    valueGetter: (value, row) => {
      if (row.uniqueId === 0) {
        return row.invName;
      }
      return value;
    },
    renderCell: (params) => {
      if (params.row.uniqueId === 0) {
        return <div className="dv-text-font">{params.row.invName}</div>;
      }
      return params.row.invName;
    },
  },
  {
    field: "city",
    headerName: "City",
    flex: 0.3,
    minWidth: 120,
  },
  {
    field: "stateandcountry",
    headerName: "State/Country",
    flex: 0.3,
    minWidth: 140,
  },
  {
    field: "level",
    headerName: "Approver Level",
    flex: 0.3,
    minWidth: 130,
  },
  {
    field: "account",
    headerName: "Account",
    flex: 0.3,
    minWidth: 120,
  },
  {
    field: "expenditures",
    headerName: "Expenditures (000's)",
    ...usdPrice,
    flex: 0.3,
  },
  {
    field: "propertytype",
    headerName: "Property Type",
    flex: 0.3,
    minWidth: 130,
  },
];

export const datagridmodigficationcolumns: GridColDef[] = [
  {
    field: "othertransactions",
    headerName: "Other Transactions",
    flex: 0.3,
    minWidth: 160,
    colSpan: (value, row) => {
      if (row.uniqueId === 0) {
        return 7;
      }
      return undefined;
    },
    valueGetter: (value, row) => {
      if (row.uniqueId === 0) {
        return row.othertransactions;
      }
      return value;
    },
    renderCell: (params) => {
      if (params.row.uniqueId === 0) {
        return <div className="dv-text-font">{params.row.othertransactions}</div>;
      }
      return params.row.invName;
    },
  },
  {
    field: "city",
    headerName: "City",
    flex: 0.3,
    minWidth: 120,
  },
  {
    field: "stateandcountry",
    headerName: "State/Country",
    flex: 0.3,
    minWidth: 140,
  },
  {
    field: "level",
    headerName: "Approver Level",
    flex: 0.3,
    minWidth: 130,
  },
  {
    field: "account",
    headerName: "Account",
    flex: 0.3,
    minWidth: 120,
  },
  {
    field: "propertytype",
    headerName: "Property Type",
    flex: 0.3,
    minWidth: 130,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 0.3,
    minWidth: 150,
  },
];
