import { createSlice } from "@reduxjs/toolkit";
import { nonBudgetedData } from "../../interfaces/Interfaces";

interface InitialState {
    data: nonBudgetedData,
    error: string,
    loading: boolean
}

const initialState: InitialState =
{
    data: {} as nonBudgetedData,
    error: '',
    loading: false
}

const nonBudgetedDataSlice = createSlice(
    {
        name: 'nonBudgetedData',
        initialState,
        reducers: {
            resetState: (state) => {
                state.data = {} as nonBudgetedData;
                state.error = "";
                state.loading = false;
            },
            nonbudgResetStateInImprovement: (state) => {
                state.data = {initialDataSet : false} as nonBudgetedData;
                state.error = "";
                state.loading = false;
            },
            updateNonBudgetedData(state, action) {
                state.data = action.payload;
            }
        },

    }
);
export const { updateNonBudgetedData ,resetState: resetNonBudgetedDataStateAction,nonbudgResetStateInImprovement } = nonBudgetedDataSlice.actions;
export default nonBudgetedDataSlice.reducer;
