import AccordionTab from '../utils/AccordianTab';
import { Container, Grid, Input, InputLabel, Link } from '@mui/material';
import TextField from '@mui/material/TextField'
import { useAppSelector } from '../../redux/hooks';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import * as React from 'react';
import { getFormData } from '../../redux/slices/getFormDataSlice';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  },
);
const VTSInformation: React.FC<Record<any, any>> = () => {

  const formData = useAppSelector(getFormData);

  const data = useAppSelector((state) => {
    return state.getFormData.formData;
  });

  return (
    <AccordionTab title={'VTS Information'} children={
      <>
        <Grid container spacing={3} >
          <Grid item xs={12} md={12}>
            <Grid>
              <label>VTS Form Link: </label>
              <Link href={formData?.vtsFormLink} color="inherit" target="_blank"
                style={{ marginLeft: "5%", color: "blueviolet" }}
              >
                {`${formData?.vtsFormLink}`}
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid>
              <label>VTS ID</label>
            </Grid>
            <Grid>
              <TextField size='small' fullWidth={true} value={formData?.vtsID} disabled />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid>
              <label>VTS Submission Date</label>
            </Grid>
            <Grid>
              <TextField size='small' fullWidth={true} value={formData?.vtsFormSubmissionDate} disabled></TextField>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid>
              <label>Submitted By</label>
            </Grid>
            <Grid>
              <TextField size='small' fullWidth={true} value={formData?.vtsFormSubmittedBy} disabled></TextField>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid>
              <label>Submitter Email</label>
            </Grid>
            <Grid>
              <TextField size='small' fullWidth={true} value={formData?.vtsSubmitterEmail} disabled></TextField>
            </Grid>
          </Grid>
          <Grid item md={6} className="grid-hide">
          </Grid>
        </Grid>
      </>
    } />
  )
}
export default VTSInformation;