import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Grid,
    TextField,
  } from "@mui/material";
  import {
    DataGridPro,
    GridCellParams,
    GridColDef,
    GridHeaderFilterCellProps,
  } from "@mui/x-data-grid-pro";
  import { useEffect, useState } from "react";
  import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
  import AddIcon from "@mui/icons-material/Add";
  import dayjs, { Dayjs } from "dayjs";

  import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
  import CheckBoxIcon from "@mui/icons-material/CheckBox";
  import fundsViewsColumn from "../Funds/fundsViewsColumn.json";
  import dashboardviewscolumns from "../Verbiagemasterscreen/dashboardviewscolumns.json";
  import { postApiCall } from "../../../services/ApiHelper";
  import { baseUrl } from "../../../components/config/Config";
  import proposalSlice from "../../../redux/slices/proposalSlice";
  import fundsemailcolumn from "../Funds_email/fundsemailcolumn.json"
  import approversviewcolumn from "../Approversmasterscreen/approversviewcolumn.json";
  import { toUpper } from "lodash";
  import * as XLSX from "xlsx";
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  
  const Fundsdatagrid = (props: any) => {
    const [rowData, setRowData] = useState<any>();
    const [headingFilters, setHeadingFilters] = useState<any>([]);
    const [selectedOptions, setSelectedOptions] = useState<any>({});
    const [paginationModel, setPaginationModel] = useState({
      pageSize: 25,
      page: 0,
    });
  
    useEffect(() => {
      setRowData(props.rowdata);
      setHeadingFilters({
        createdOn: props.gridDropDown.CreatedOn,
        createdBy: props.gridDropDown.CreatedBy,
        updatedBy: props.gridDropDown.UpdatedBy,
        updatedAt: props.gridDropDown.UpdatedAt,
       
        fundName: props.gridDropDown.fundName,
        authName: props.gridDropDown.authName,
      });
    }, [props.rowdata]);
    const customTextHeaderFilter = (params: GridHeaderFilterCellProps) => {
      return (
        <TextField
          id="standard-basic"
          variant="standard"
          key={props.selectedTab || props.isActive}
          onChange={(event) => {
            handleFilterChange(event.target.value, params.item.field);
          }}
        />
      );
    };
  
    // const dropDownFiltering = (
    //   value: Array<string>,
    //   field: string,
    //   filteredRows: any
    // ) => {
    //   var filteredDropdownData: any = [];
  
    //   value.forEach((selectedDropdown: string) => {
    //     var filteredData = filteredRows.filter(
    //       (fundRow: any) =>
    //         fundRow[field] !== undefined &&
    //         toUpper(fundRow[field]) === toUpper(selectedDropdown)
    //     );
  
    //     filteredData.forEach((perFilterd: any) => {
    //       filteredDropdownData.push(perFilterd);
    //     });
    //   });
  
    //   return filteredDropdownData;
    // };
    const dropDownFiltering = (value: Array<string>, field: string, filteredRows: any) => {
      var filteredDropdownData: any = [];
  
      if (field === "fund") {
          value.forEach((selectedDropdown: string) => {
              var filteredData = filteredRows.filter((fundRow: any) => fundRow[field] !== undefined && (toUpper(fundRow[field]).includes(toUpper(selectedDropdown))));
              console.log(filteredDropdownData);
  
              filteredData.forEach((perFilterd: any) => {
                  var isIncluded = false;
                  filteredDropdownData.forEach((element: any) => {
                      if (element.id === perFilterd.id) {
                          isIncluded = true;
                      }
                  });
  
                  if (!isIncluded) {
                      filteredDropdownData.push(perFilterd);
                  }
  
              });
          });
      } else {
          value.forEach((selectedDropdown: string) => {
              var filteredData = filteredRows.filter((fundRow: any) => fundRow[field] !== undefined && (toUpper(fundRow[field]) === toUpper(selectedDropdown)));
  
              filteredData.forEach((perFilterd: any) => {
                  filteredDropdownData.push(perFilterd);
              });
          });
      }
  
      return filteredDropdownData;
  }
    const handleFilterChange = (e: any, field: string) => {
      var value = e;
      console.log(value, "value");
      var fieldName = field;
      console.log(fieldName, "field");
      var filterModel = {
        items: [],
      };
      console.log(filterModel, "filtermodel");
      setSelectedOptions({
        ...selectedOptions,
        ...{
          [fieldName]: value,
        },
      });
      filterModel.items = {
        ...selectedOptions,
        ...{
          [fieldName]: value,
        },
      };
      filterData(filterModel);
    };
  
    const filterData = (filterModel: any) => {
      var filteredRowData: any = props.rowdata;
      console.log(filterModel.items, "test");
      for (const key of Object.keys(filterModel.items)) {
        switch (typeof filterModel.items[key]) {
          case "object":
            console.log(filterModel.items[key].length);
            if (filterModel.items[key].length !== 0) {
              filteredRowData = dropDownFiltering(
                filterModel.items[key],
                key,
                filteredRowData
              );
            } else {
            }
  
            break;
          case "string":
            if (filterModel.items[key].length !== 0) {
              filteredRowData = filteredRowData?.filter(
                (fundRow: any) =>
                  fundRow[key] !== undefined &&
                  toUpper(fundRow[key]).includes(toUpper(filterModel.items[key]))
              );
            } else {
            }
            break;
          default:
            break;
        }
      }
      setRowData(filteredRowData);
    };
  
    const customDropDownHeaderFilter = (params: GridHeaderFilterCellProps) => {
      var columnFilterList = headingFilters[params.item.field] || [];
      console.log(headingFilters, "columns");
      console.log(params.item.field, "params");
  
      return (
        <Autocomplete
          className="autocomplete-root"
          ListboxProps={{
            className: "autocomplete-dashboard"
          }}
          multiple
          limitTags={1}
          id="tags-standard"
          key={props.submitClicked}
          onChange={(event, newInputValue) =>
            handleFilterChange(newInputValue, params.item.field)
          }
          options={columnFilterList}
          disableCloseOnSelect
          renderOption={(props, option: any, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                checked={selected}
              />
              {option}
            </li>
          )}
          fullWidth
          renderInput={(params) => <TextField {...params} variant="standard" />}
        />
      );
    };
  
    const columns: any = fundsemailcolumn.colDef.map((colDef: any) => {
      let { valueFormatter, ...rest } = colDef;
      if (
        colDef.field === "createdBy" ||
        colDef.field === "createdOn" ||
        colDef.field === "updatedBy" ||
        colDef.field === "updatedAt" ||
       
        colDef.field === "fundName" 
      ) {
        return {
          ...rest,
          renderHeaderFilter: customDropDownHeaderFilter,
        };
      } 
      else 
      if (colDef.field === "authName") {
        return {
          ...rest,
          renderHeaderFilter: customDropDownHeaderFilter,
          cellClassName: (params: GridCellParams<any, number>) => {
            if (!params.value) {
              return "project-name-blank";
            }
            return "project-name-cell";
          },
        };
      }
      else {
        return {
          ...rest,
          renderHeaderFilter: customTextHeaderFilter,
        };
      }
    });
    const handleCellClick = (params: any) => {
      if (params.field === "authName") {
        props.updateRow({
          id: params.row.id,
          fundName: params?.row?.fundName,
          authorizationType: {
            name: params.row.authCode,
            value: params.row.authName,
          },
          emails : params.row.emails,
          isActive: params.row.isActive === "Yes"

        });
        props.type("Edit");
        props.open(true);
        props.addApproverdropdown()
        console.log(params.row  , "isactive")

      }
    };
    const exportExcel = () => {
      const data = rowData.map((row: any) =>
        fundsemailcolumn.colDef.map((col) => row[col.field] || "")
      );
      const worksheet: any = XLSX.utils.aoa_to_sheet([
        fundsemailcolumn.colDef.map((col) => col.headerName),
        ...data,
      ]);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "FundsEmail");
      const now = dayjs();
      const hour = now.format("HH");
      const minute = now.format("mm");
      const day = now.format("DD");
      const month = now.format("MM");
      const year = now.format("YYYY");
      const seconds = now.format("ss");
      const timestamp = `${day}${month}${year}${hour}${minute}${seconds}`;
      XLSX.writeFile(workbook, `fundsEmail${timestamp}.xlsx`);
    };
  
    console.log(rowData, "rowdata");
    return (
      <>
        <Grid container justifyContent="flex-end" spacing={2} sx={{ mb: 2 }}>
          <Grid item>
            {" "}
            <Button
              variant="contained"
              className="primary"
              onClick={() => {
                props.addApproverdropdown();
                props.type("Add");
              }}
            >
              <AddIcon sx={{ mr: 1 }} />
              Add
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" className="primary" onClick={exportExcel}>
              <FileDownloadOutlinedIcon sx={{ mr: 1 }} />
              EXCEL
            </Button>
          </Grid>
        </Grid>
        <Box className="dashboard-table-wrapper">
          <div style={{ width: "100%" }}>
            <DataGridPro
              className="dashboard-dataGrid"
              getRowId={(row) => row.id}
              rows={rowData ?? []}
              columns={columns}
              headerFilters
              columnHeaderHeight={48}
              headerFilterHeight={48}
              pageSizeOptions={[5, 10, 100]}
              rowHeight={40}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              loading={props.loading}
              pagination
              disableColumnMenu={true}
              autoHeight
              onCellClick={handleCellClick}
              hideFooter={false}
            />
          </div>
        </Box>
      </>
    );
  };
  
  export default Fundsdatagrid;
  