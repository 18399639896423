import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, TextField } from "@mui/material";

const ApproveRejectDialog: React.FC<Record<any, any>> = (p) => {

    const [approveRejectComment, setApproveRejectComment] = useState("");
    const [commentRequired, setCommentRequired] = useState(false)
    const handleClose = () => {
        p.setOpenDialogBox(false);
    };

    const handleSubmit = () => {
        if (p.isRejected && approveRejectComment === "") {
            setCommentRequired(true)
        }
        else {
            p.setConfirm(true);
            p.setOpenDialogBox(false);
        }

    };

    return (
        <Dialog
            open={p.openDialogBox}
            onClose={handleClose}
            className="common-dialog-box"
        >
            <DialogTitle id="alert-dialog-title">
                Comments
                {p.isRejected && <span className="mandatory"> * </span>}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <TextField fullWidth error={commentRequired} onChange={(event) => {
                        p.setComment(event.target.value);
                        setApproveRejectComment(event.target.value)
                    }}></TextField>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button className="btn-tertiary" variant="contained" data-testid="close-dialog-button" onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    data-testid="submit-dialog-button"
                    className="btn-primary"
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export { ApproveRejectDialog };