import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Autocomplete,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { authData } from "../../../redux/slices/authDataSlice";
import { baseUrl } from "../../../components/config/Config";
import { postApiCall } from "../../../services/ApiHelper";
import { verbiageProjectValidate } from "../../../interfaces/Interfaces";
import { ToastMessage } from "../../../components/utils/ToastMessage";
import { verbiageProjectResponse } from "../../../interfaces/Interfaces";
import { toast } from "../../../interfaces/Interfaces";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const VerbiageDialogBox = (props: any) => {
  const [toast, setToast] = useState(false);
  const [responseData, setResponseData] = useState<verbiageProjectResponse>({
    id: null,
    fundName: [],
    detailText: "",
    authorizationType: {
      name: "",
      value: "",
    },
    isActive : true

  });

  const [validateData, setValidateData] = useState<verbiageProjectValidate>({
    fundName: false,
    authcode: false,
    detailText: false,
  });
  const [toastMessage, setToastMessage] = useState<toast>({
    message: "",
    severity: "",
  });
  const [selectedOptions, setSelectedOptions] = useState<any>();
  const [disableOtherOptions, setDisableOtherOptions] =
    useState<boolean>(false);
  useEffect(() => {
    setResponseData(props.updateRow);
  }, [props.updateRow]);
  const checkValidation = () => {
    setValidateData({
      authcode: responseData.authorizationType.name == "" ? true : false,
      fundName: responseData.fundName?.length == 0 ? true : false,
      detailText: responseData.detailText == "" ? true : false,
    });
    return (
      responseData.authorizationType.name == "" ||
      responseData.fundName?.length == 0 ||
      responseData.detailText == ""
    );
  };

  const handleSubmitClick = async () => {
    var checkFormValidation = checkValidation();
    if (checkFormValidation !== true) {
      handleSubmit();
    } else {
      setToast(true);
      setToastMessage({
        message: "Please fill in the required field(s)",
        severity: "error",
      });
      setTimeout(() => setToast(false), 4000);
    }
  };

  const handleSubmit = async () => {
    var url = `${baseUrl}authverbiage/data`;
    try {
      const payloaddata = {
        id: responseData?.id,
        fundName: responseData?.fundName,
        detailText: responseData?.detailText,
        authcode: responseData?.authorizationType.name,
        isActive : responseData?.isActive

      };
      const res = await postApiCall(url, payloaddata).then(() => {
        setToast(true);
        setToastMessage({
          message: "Authorizations verbiage added sucessfully",
          severity: "success",
        });
        setTimeout(() => setToast(false), 4000);
        setTimeout(() => props.setOpen(false), 4000);
        props.submitClicked(true);
      });
    } catch (error: any) {
      setToast(true);
      setToastMessage({
        message: `${error.response?.data?.description} (${error?.response?.data?.code})`,
        severity: "error",
      });
      setTimeout(() => setToast(false), 4000);
    }
  };
  
  return (
    <>
    <Dialog open={props.open} onClose={props.onClose} maxWidth="md" fullWidth  className="common-dialog-box">
      <DialogTitle id="alert-dialog-title">
      {props.type} Authorizations Verbiage
      </DialogTitle>
      <DialogContent
        style={{
          height: "calc(60vh - 64px)",
          justifyContent: "center",
        }}
        className='form-field-label'
      >
        
        <Grid container spacing={3}>
          <Grid item xs={6} md={6}>
            <Grid>
              <label>Authorizations Type:</label>
              <span className="mandatory"> * </span>
            </Grid>
            <Grid>
              <Autocomplete
                options={
                  props?.authtype
                    ? Object?.keys(props?.authtype)?.map((key) => ({
                        name: key,
                        value: props?.authtype[key],
                      }))
                    : []
                }
                value={responseData?.authorizationType}
                getOptionLabel={(option) => option?.value}
                onChange={(event: any, newValue: any) => {
                  setResponseData({
                    ...responseData,
                    authorizationType: newValue,
                  });
                  console.log(newValue, "mew");

                  setValidateData({ ...validateData, authcode: false });
                }}
                renderInput={(params) => (
                  <TextField
                    error={validateData.authcode}
                    {...params}
                    variant="outlined"
                    fullWidth
                  />
                )}
                size="small"
              />
            </Grid>
          </Grid>
          <Grid item xs={6} md={6}>
            <Grid>
              <label htmlFor="field1">Fund Name</label>

              <span className="mandatory"> * </span>
            </Grid>
            <Grid>
              <Autocomplete
                multiple
                options={props?.fundNames}
                id="checkboxes-tags-demo"
                disableCloseOnSelect
                value={responseData?.fundName ?? []}
                limitTags={1}
                onChange={(event, newInputValue) => {
                  console.log("fundName", newInputValue);
                  setResponseData({ ...responseData, fundName: newInputValue });

                  setValidateData({ ...validateData, fundName: false });
                }}
                renderOption={(props, option: any, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      checked={selected}
                      disabled={true}
                    ></Checkbox>
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    error={validateData?.fundName}
                    {...params}
                    variant="outlined"
                    fullWidth
                  />
                )}
                size="small"
              />{" "}
            </Grid>
            {/* Bottom field, centered */}
          </Grid>

          <Grid item xs={12} md={12}>
            <label htmlFor="field1">Verbiage</label>
            <span className="mandatory"> * </span>
            <TextField
              fullWidth
              minRows={3}
              maxRows={7}
              multiline
              error={validateData?.detailText}
              value={responseData?.detailText}
              onChange={(event) => {
                setResponseData({
                  ...responseData,
                  detailText: event.target.value,
                });
                setValidateData({ ...validateData, detailText: false });
              }}
            >
              {" "}
            </TextField>
          </Grid>
          <Grid item xs={12} md={12}>
                        <FormControlLabel
                            control={<Checkbox checked={responseData?.isActive}
                                onChange={(event) => setResponseData({ ...responseData, isActive: event.target.checked })} />}
                            label="Is Active"
                            sx={{
                                '& .MuiFormControlLabel-label': {
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    color: '#343434',
                                    lineHeight: '24px'

                                },
                            }} />
                    </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            props.setOpen(false);
          }}
          color="primary"
          variant="outlined"
          className="btn-tertiary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleSubmitClick();
          }}
          className="btn-primary"
          variant="contained"
        >
          Submit
        </Button>
      </DialogActions>
      
    </Dialog>
    {toast && (
        <ToastMessage
          message={toastMessage.message}
          severity={toastMessage.severity}
        />
      )}
      </>
  );
};
export default VerbiageDialogBox;
