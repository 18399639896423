export function validatePropertyProjectDetails(propertyDetailsList: any) {

    const ProjectManagementFeeNAFilter = propertyDetailsList?.filter((elm: any) => !elm.projectManagementFeeNA);

    var tempArr: Array<{ id: number; projectMgmtFee: boolean; AuthAmount: boolean; }> = [];

    if (propertyDetailsList?.length === 0) {
        tempArr = [{
            id: 0,
            projectMgmtFee: true,
            AuthAmount: false
        }];
    }

    ProjectManagementFeeNAFilter?.forEach((element: any) => {
        tempArr?.push({
            id: element.id,
            projectMgmtFee: Number.isNaN(element.projectManagementFee) || element.projectManagementFee <= 0,
            AuthAmount: false
        });
    });

    return tempArr;
}

export function isPropertyProjectDetailsError(propertyDetailArray: any) {
    var isError = false;

    propertyDetailArray.forEach((element: any) => {
        if (element.projectManagementFeeNA === false && element.projectManagementFee <= 0) {
            isError = true;
        };
    });

    return isError;
}