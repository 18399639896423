import { createSlice } from "@reduxjs/toolkit";
import { budgetedData } from "../../interfaces/Interfaces";

interface InitialState {
    data: budgetedData,
    error: string,
    loading: boolean
}
const initialState: InitialState =
{
    data: {} as budgetedData,
    error: '',
    loading: false
}

const budgetedDataSlice = createSlice(
    {
        name: 'budgetedData',
        initialState,
        reducers: {
            resetState: (state) => {
                state.data = {} as budgetedData;
                state.error = "";
                state.loading = false;
            },
            budgResetStateInImprovement: (state) => {
                state.data = {initialDataSet : false} as budgetedData;
                state.error = "";
                state.loading = false;
            },
            updateBudgetedData(state, action) {
                state.data = action.payload;
            }
        },

    }
);
export const { updateBudgetedData ,resetState: resetBudgetedDataStateAction, budgResetStateInImprovement} = budgetedDataSlice.actions;
export default budgetedDataSlice.reducer;
