import { createTheme } from "@mui/material/styles";

export const getApiBaseEndPoint = () => {
    const sessionConfig = localStorage.getItem("config");
    if (sessionConfig === null) {
        console.log("App settings (msal) are not loaded into the session store yet!");
        return "";
    }

    const appSettings = JSON.parse(sessionConfig);
    const protectedResources = appSettings?.protectedResources;    
    return protectedResources?.auth?.baseEndPoint;
}
export const baseUrl = getApiBaseEndPoint();
export const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 767,
        md: 992,
        lg: 1280,
        xl: 1536,
      },
    },
  });