import AccordionTab from "../utils/AccordianTab";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useState, useEffect } from "react";
import Budgeted from "./Budgeted";
import NonBudgeted from "./NonBudgeted";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getFormData } from "../../redux/slices/getFormDataSlice";
import { Grid } from "@mui/material";
import { authorizationAmountChangeByAm } from "../../redux/slices/commonslice";
import { updateAuthorizationData } from "../../redux/slices/authorizationDataSlice";

const Improvement: React.FC<Record<any, any>> = (props) => {

  const dispatch = useAppDispatch()

  const formData = useAppSelector(getFormData);

  const improvementSlice = useAppSelector((state) => { return state.commonData.commonData });
  const authorizationData = useAppSelector((state) => { return state.authorizationData.data })

  const [selectedOption, setSelected] = useState("");
  const [showForm, setShowForm] = useState<boolean>(false);

  const handleOptionChange = (event: any) => {
    setShowForm(true)
    setSelected(event.target.value);

    dispatch(updateAuthorizationData({
      ...authorizationData,
      selectedApprovers: []
    }));
    
    if (event.target.value === "Budgeted") {
      dispatch(authorizationAmountChangeByAm({ ...improvementSlice, formType: true }));
    }
    else if (event.target.value === "Non-Budgeted") {
      dispatch(authorizationAmountChangeByAm({ ...improvementSlice, formType: false }));
    }
  };
  useEffect(() => {
    if (formData !== null) {
      
      if (formData?.formType !== null && formData?.formType !== undefined) {
        setShowForm(true);
        (formData?.formType ? (setSelected("Budgeted")) : !formData?.formType ? setSelected("Non-Budgeted") : setSelected(""));
        dispatch(authorizationAmountChangeByAm({ ...improvementSlice, formType: formData?.formType }));
      }
    }
  }, [formData]);

  return (
    <AccordionTab
      title={"Improvement"} children=
      {
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <label>Is this budgeted or non-budgeted/existing project addition ?</label>
            </Grid>
            <Grid item xs={12} md={5} style={{ paddingTop: '14px' }}>
              <RadioGroup
                row
                aria-label="budgeted-option"
                name="budgeted-option"
                defaultValue={""}
                value={selectedOption}
                onChange={handleOptionChange}
              >
                <FormControlLabel
                  value="Budgeted"
                  control={<Radio />}
                  label="Budgeted"
                  disabled={!formData?.isFormEditable}
                />
                <FormControlLabel
                  value="Non-Budgeted"
                  control={<Radio />}
                  label="Non-Budgeted/Existing project addition"
                  disabled={!formData?.isFormEditable}
                />
              </RadioGroup>
            </Grid>
            {showForm && ((formData?.propertyManagementCompanyName === "OMNI" || selectedOption === "Non-Budgeted")
              ? (<NonBudgeted buttonclicked={props.buttonclicked} selected={selectedOption} />)
              : <Budgeted buttonclicked={props.buttonclicked} selected={selectedOption} />)
            }
          </Grid>
        </div>
      } />
  )
}

export default Improvement;