export function RecommendbyValidator(selectedApprovers: any, recommended: any) {
  let duplicatecheck = false;

  selectedApprovers.forEach((item: any) => {
    if (item.mail === recommended) {
      duplicatecheck = true;
    }
  });
  return duplicatecheck;
}
