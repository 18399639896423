import { useSelector } from "react-redux";
import loaderSlice from "../../redux/slices/loaderSlice";
import { setLoading } from "../../redux/slices/loaderSlice";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";

import { useAppSelector } from "../../redux/hooks";
import { theme } from "../config/Config";
const Loader: React.FC = () => {
  const loader = useAppSelector((state) => state.loaderSlice.loading);
  const submitErrorModel = useAppSelector((state) => state.submitFormError);

  return loader ? (

    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,

        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        zIndex: 1000,
      }}
    >
      <CircularProgress style={{ color: '#0263E0'
  }} />
     </Box>
    
  ) : (
    <></>
  );
};

export default Loader;
