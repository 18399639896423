import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";
const DialogBox: React.FC<Record<any, any>> = (p) => {
    const handleClose = () => {
        p.setOpenDialogBox(false);
    };

    const handleSubmit = () => {
        p.setConfirm(true);
        p.setOpenDialogBox(false);
    };

    return (
        <Dialog
            open={p.openDialogBox}
            onClose={handleClose}
            className="common-dialog-box"
        >
            <DialogTitle id="alert-dialog-title">
                {p.dialogTitle}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {p.dialogText}
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button className="btn-tertiary" variant="contained" data-testid = "close-dialog-button" onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    data-testid = "submit-dialog-button"
                    className="btn-primary"
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export { DialogBox };