export function approvalErrorHandler(selectedApprovers: any, approversList: any) {
    var filteredApprovers = selectedApprovers.filter((elem: any) => elem.name.length !== 0);
    var tempAuthApproversError: Array<any> = [];
    var dublicateApprovers = false;

    if (filteredApprovers.length === 0) {
        approversList.forEach((element: any) => {
            tempAuthApproversError.push({ level: element.level, isEmpty: true, approver: element.approver + 1 })
        });
    } else if (filteredApprovers.length === approversList.length) {
        
        selectedApprovers.forEach((element: any, i: number) => {
            for (let index = i + 1; index < selectedApprovers.length; index++) {

                if (element.name === selectedApprovers[index].name) {
                    tempAuthApproversError.push({ level: selectedApprovers[index].level, isEmpty: true, approver: selectedApprovers[index].approver });
                }
            }
        });
        approversList.forEach((apprElement: any) => {
            var isSelectedAgain = false;
            tempAuthApproversError.forEach((errElm: any) => {
                if (apprElement.approver + 1 === errElm.approver) {
                    isSelectedAgain = true;
                    dublicateApprovers = true;
                }
            });

            if (!isSelectedAgain) {
                tempAuthApproversError.push({ level: apprElement.level, isEmpty: false, approver: apprElement.approver + 1 })
            }
        });

    } else if (selectedApprovers !== null) {
        filteredApprovers.forEach((element: any) => {
            var approverObj = approversList.filter((elem: any) => element.approver - 1 !== elem.approver);

            approverObj.forEach((approverObjelement: any) => {
                tempAuthApproversError.push({ level: approverObjelement.level, isEmpty: true, approver: approverObjelement.approver + 1 });
            });

            for (let index = 0; index < approversList.length - approverObj.length; index++) {
                tempAuthApproversError.push({ level: "", isEmpty: false, approver: element.approver });
            }
        });

        tempAuthApproversError.forEach((errorElm: any, errInd: number) => {
            if (errorElm.isEmpty) {
                var replacingVariable = tempAuthApproversError[errorElm.approver - 1];
                tempAuthApproversError[errorElm.approver - 1] = errorElm;
                tempAuthApproversError[errInd] = replacingVariable;
            }
        });
    }



    var returnObject = {errorArray : tempAuthApproversError.sort((a, b) => a.approver - b.approver), isDublicate:dublicateApprovers}

    return {errorArray : tempAuthApproversError.sort((a, b) => a.approver - b.approver), isDublicate:dublicateApprovers};
}

export function isApprovalError(approverErrorArray: any) {

    var isError = false;
    approverErrorArray.forEach((element: any) => {
        if (element.isEmpty) { isError = true }
    });

    return isError;
}