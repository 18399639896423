import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAuthorizationRequest, } from "../../interfaces/Interfaces";
import { FormData } from "../../interfaces/Interfaces";
import { baseUrl } from "../../components/config/Config";
import { getApiCall } from "../../services/ApiHelper";

type InitialState = {
    formData: FormData | null,
    error: string,
    loading: boolean
}

const initialState: InitialState = {
    formData: null,
    error: '',
    loading: false
}

export const getNewFormInfo = createAsyncThunk("getNewFormInfo", async (payload: getAuthorizationRequest) => {
    try {
        var url = `${baseUrl}Authorizations/newforminfo?invId=${payload.invId}&authType=${payload.authType}&fundName=${payload.fundName}`;
        const response = await getApiCall<FormData>(url);
        return response;
    } catch (error: any) {
        console.error('Error in GET request:', error);
        throw error;
    }
})

const newFormDataSlice = createSlice({
    name: 'newFormData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
            builder.addCase(getNewFormInfo.pending, (state) => {
                state.loading = true;
            })
            .addCase(getNewFormInfo.fulfilled, (state, action: PayloadAction<FormData | null>) => {
                state.loading = false;
                state.formData = action.payload;
            })
            .addCase(getNewFormInfo.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'something went wrong';
            })
    }
});

export const getNewFormData = (state:any) => state.newFormData.formData

export default newFormDataSlice.reducer;
