import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
    data: any,
    error: string,
    loading: boolean
}
const initialState: InitialState =
{
    data: {} as any,
    error: '',
    loading: false
}

const dashboardDropdownSlice=createSlice(
    {
        name: 'dashboardDropdown',
        initialState,
        reducers:{
            dashboardDropdownData(state,action){
                state.data=action.payload;
            }
        }, 
        
    }
);
export const { dashboardDropdownData } = dashboardDropdownSlice.actions;
export default dashboardDropdownSlice.reducer;
