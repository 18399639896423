import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField, Autocomplete, Radio, FormControlLabel, RadioGroup, FormGroup, Checkbox } from '@mui/material';
import { baseUrl } from '../../../components/config/Config';
import { postApiCall } from '../../../services/ApiHelper';
import { ToastMessage } from '../../../components/utils/ToastMessage';
import { authTypeResponse, authTypeValidate, capProjectValidate, toast } from '../../../interfaces/Interfaces';
import { capProjectResponse } from '../../../interfaces/Interfaces';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { makeStyles } from "tss-react/mui"
import { DateField, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { toUpper } from 'lodash';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const useStyles = makeStyles()((theme) => {
    return {
        input: {
            "& input[type=number]": {
                "-moz-appearance": "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
            },
            "& input[type=number]::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
            },
        },
    };
});

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AuthorizationTypesDialogBox = (props: any) => {
    const [toast, setToast] = useState(false);
    const [responseData, setResponseData] = useState<authTypeResponse>(
        {
            id: null,
            name: "",
            formHeading: "",
            rolesAssignedTo: [],
            templateUsed: "",
            isActive: true,
        }
    );

    const [initailData, setInitialData] = useState<any>({
        name: ""
    });

    const [validateData, setValidateData] = useState<authTypeValidate>(
        {
            name: false,
            formHeading: false,
            rolesAssignedTo: false,
            templateUsed: false,
        }
    )

    const [toastMessage, setToastMessage] = useState<toast>({
        message: "",
        severity: "",
    });

    useEffect(() => {
        setResponseData(props.updateRow);
        console.log(props.updateRow);

        setInitialData({
            name: props.updateRow.name,
        })

    }, [props.updateRow]);

    const checkValidation = () => {
        setValidateData({
            name: responseData.name == "" ? true : false,
            formHeading: responseData.formHeading == "" ? true : false,
            rolesAssignedTo: responseData?.rolesAssignedTo === undefined || responseData?.rolesAssignedTo[0] === "" || responseData?.rolesAssignedTo.length === 0 ? true : false,
            templateUsed: responseData.templateUsed == "" ? true : false,
        });

        return responseData.name == "" || responseData.formHeading == "" || responseData.templateUsed == "" || responseData?.rolesAssignedTo === undefined || responseData?.rolesAssignedTo[0] === "" || responseData?.rolesAssignedTo.length === 0
    }

    const checkDuplicate = (rowData: any[]) => {
        if (props.type === "Edit" && toUpper(initailData.name) === toUpper(responseData.name)) {
            return false;
        }
        else {
            var check = rowData.some(item =>
                toUpper(item.name) === toUpper(responseData.name)
            );
            return check;
        }
    }

    const handleSubmitClick = async () => {
        var checkFormValidation = checkValidation()
        var checkForDuplicate = checkDuplicate(props.rowdata)
        if (!checkFormValidation
            && !checkForDuplicate
        ) {
            handleSubmit()
        }
        else if (checkForDuplicate) {
            setToast(true);
            setToastMessage({
                message: "Authorization type already exists",
                severity: "error",
            });
            setTimeout(() => setToast(false), 4000);
        }
        else {
            setToast(true);
            setToastMessage({
                message: "Please fill in the required field(s)",
                severity: "error",
            });
            setTimeout(() => setToast(false), 4000);
        }
    }

    const handleSubmit = async () => {
        var url = `${baseUrl}AuthorizationTypes/data`;
        try {
            const payload = {
                ...responseData
            }

            const res = await postApiCall(url, payload).then(() => {
                setToast(true);
                setToastMessage({
                    message: "Authorization type added sucessfully",
                    severity: "success",
                });
                setTimeout(() => setToast(false), 3000);
                setTimeout(() => props.setOpen(false), 3000);

            });
            props.submitClicked(true)

        } catch (error: any) {
            setToast(true);
            setToastMessage({
                message: `${error.response.data.description} (${error.response.data.code})`,
                severity: "error",
            });
            setTimeout(() => setToast(false), 4000);
        }
    }

    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                maxWidth="md"
                fullWidth
                className="common-dialog-box">
                <DialogTitle id="alert-dialog-title" >
                    {props.type} Authorization Type
                </DialogTitle>
                <DialogContent style={{
                    height: 'calc(60vh - 64px)',
                    justifyContent: 'center',
                }}
                    className='form-field-label'>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Grid>
                                <label>Authorization Name</label><span className="mandatory"> * </span>
                            </Grid>
                            <Grid>
                                <TextField size='small'
                                    fullWidth
                                    error={validateData.name}
                                    value={responseData?.name}
                                    onChange={(event) => { setResponseData({ ...responseData, name: event.target.value }); setValidateData({ ...validateData, name: false }) }}> </TextField>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid>
                                <label>Authorization Header</label><span className="mandatory"> * </span>
                            </Grid>
                            <Grid>
                                <TextField size='small'
                                    fullWidth
                                    error={validateData.formHeading}
                                    value={responseData?.formHeading}
                                    onChange={(event) => { setResponseData({ ...responseData, formHeading: event.target.value }); setValidateData({ ...validateData, formHeading: false }) }}> </TextField>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <Grid>
                                <label htmlFor="field1">Assigned Role</label>

                                <span className="mandatory"> * </span>
                            </Grid>
                            <Grid>
                                <Autocomplete
                                    multiple
                                    options={!props.invLoading ? props?.assignedRolesDropdown : []}
                                    id="checkboxes-tags-demo"
                                    disableCloseOnSelect
                                    value={(responseData?.rolesAssignedTo?.length === 1 && responseData?.rolesAssignedTo[0] === "") || responseData?.rolesAssignedTo?.length === 0 ? [] : responseData?.rolesAssignedTo}
                                    limitTags={1}
                                    onChange={(event, newInputValue: string[]) => {
                                        setResponseData({ ...responseData, rolesAssignedTo: newInputValue });
                                        setValidateData({ ...validateData, rolesAssignedTo: false })
                                    }}

                                    renderOption={(props, option: any, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                checked={selected}
                                                disabled={true}
                                            ></Checkbox>
                                            {option}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            error={validateData?.rolesAssignedTo}
                                            {...params}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                    size="small"
                                />{" "}
                            </Grid>
                            {/* Bottom field, centered */}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid >
                                <label>Template</label><span className="mandatory"> * </span>
                            </Grid>
                            <Grid>
                                <Autocomplete
                                    id="template"

                                    loading={props.invLoading}
                                    options={!props.invLoading ? props?.templatesDropdown : []}
                                    size="small"
                                    fullWidth
                                    value={responseData?.templateUsed}
                                    onChange={(event, newInputValue) => {
                                        setResponseData({ ...responseData, templateUsed: newInputValue });
                                        setValidateData({ ...validateData, templateUsed: false })
                                    }}
                                    renderInput={(params) =>
                                        <TextField
                                            error={validateData.templateUsed}
                                            {...params} />}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormControlLabel
                                control={<Checkbox checked={responseData?.isActive}
                                    onChange={(event) => setResponseData({ ...responseData, isActive: event.target.checked })} />}
                                label="Is Active"
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                        color: '#343434',
                                        lineHeight: '24px'

                                    },
                                }} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>

                    <Button onClick={() => { props.setOpen(false) }} color="primary" variant="outlined" className="btn-tertiary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => { handleSubmitClick() }}
                        className="btn-primary" variant="contained">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            {toast && (
                <ToastMessage
                    message={toastMessage.message}
                    severity={toastMessage.severity}
                />
            )}
        </>

    );
};
export default AuthorizationTypesDialogBox;