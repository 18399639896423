import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
    data: { invID: string, authName: string, authId: string, authCode: string, fundName: string, authType: string }
}
const initialState: InitialState =
{
    data: {} as { invID: string, authName: string, authId: string, authCode: string, fundName: string, authType: string }
}

const authDataSlice = createSlice(
    {
        name: 'authData',
        initialState,
        reducers: {
            resetState: (state) => {
                state.data = {} as { invID: string, authName: string, authId: string, authCode: string, fundName: string, authType: string };
            },
            authData(state, action) {
                state.data = action.payload;
            }
        },

    }
);
export const { authData, resetState: resetAuthDataStateAction } = authDataSlice.actions;
export default authDataSlice.reducer;
