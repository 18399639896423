import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { makeStyles } from "tss-react/mui";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import * as React from "react";
import _debounce from "lodash/debounce";
import AccordionTab from "../utils/AccordianTab";
import { useCallback, useEffect } from "react";
import { updateModProjectDetail } from "../../redux/slices/modProjectDetailSlice";
import { bid } from "../../constant";
import { updateErrorFields } from "../../redux/slices/submitFormErrorSlice";
import { ecoTermChange } from "../../redux/slices/commonslice";
import { updateAuthorizationData } from "../../redux/slices/authorizationDataSlice";

const useStyles = makeStyles()((theme) => {
  return {
    input: {
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  };
});

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        prefix="$ "
      />
    );
  }
);

const ModProjectDetails: React.FC<Record<any, any>> = (props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const formData = useAppSelector((state) => state.getFormData.formData);
  const ModProjectDetailData = useAppSelector(
    (state) => state.modProjectDetailsData.data
  );
  const submitErrorModel = useAppSelector((state) => state.submitFormError);
  const authorizationData = useAppSelector((state) => {
    return state.authorizationData.data;
  });
  const [ecoTerms, setEcoTerms] = React.useState("");

  useEffect(() => {
    setEcoTerms(String(formData?.ecoTerms));
    dispatch(
      updateModProjectDetail({
        name: formData?.projectName,
        authAmount: formData?.authAmount,
        projectDescription: formData?.description,
        ecoTerms: String(formData?.ecoTerms),
      })
    );
  }, [formData?.id]);

  const updateEcoTerms = (event: any) => {
    setEcoTerms(event.target.value);

    dispatch(
      updateModProjectDetail({
        ...ModProjectDetailData,
        ecoTerms: event.target.value,
      })
    );
    dispatch(
      updateErrorFields({ ...submitErrorModel.data, modEcoterms: false })
    );
    dispatch(ecoTermChange(event.target.value));
    dispatch(
      updateAuthorizationData({
        ...authorizationData,
        selectedApprovers: [],
      })
    );
  };

  const updateAmount = (e: any) => {
    dispatch(
      updateModProjectDetail({
        ...ModProjectDetailData,
        authAmount: e.target.value,
      })
    );
    dispatch(updateErrorFields({ ...submitErrorModel.data, modAmount: false }));
  };
  function usePrevious(value: any) {
    const ref = React.useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  var prevAmount: any = usePrevious(ModProjectDetailData.authAmount);

  useEffect(() => {
    var amountPrevCheck =
      prevAmount !== undefined &&
      prevAmount !== null &&
      String(prevAmount) !== ModProjectDetailData.authAmount;
    if (amountPrevCheck) {
      dispatch(
        updateAuthorizationData({
          ...authorizationData,
          selectedApprovers: [],
        })
      );
    }
  }, [ModProjectDetailData.authAmount]);
  return (
    <AccordionTab
      title={"Project Detail"}
      children={
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Grid>
                <label>Project Name</label>
                <span className="mandatory"> *</span>
              </Grid>
              <Grid>
                <TextField
                  size="small"
                  fullWidth={true}
                  value={ModProjectDetailData?.name}
                  disabled={!formData?.isFormEditable}
                  error={submitErrorModel.data.modprojectname}

                  onChange={(event: any) =>{
                    dispatch(
                      updateModProjectDetail({
                        ...ModProjectDetailData,
                        name: event.target.value,
                      })
                    
                    )
                    dispatch(
                        updateErrorFields({
                          ...submitErrorModel.data,
                          modprojectname: false,
                        })
                      );
                    
                  }}
                ></TextField>
              </Grid>
            </Grid>
            <Grid item md={6} className="grid-hide"></Grid>
            <Grid item xs={12}>
              <Grid>
                <label>Detailed Project Description</label>
              </Grid>
              <Grid>
                <TextField
                  value={ModProjectDetailData?.projectDescription}
                  multiline
                  disabled={!formData?.isFormEditable}
                  minRows={3}
                  maxRows={7}
                  size="small"
                  fullWidth={true}
                  onChange={(event: any) =>
                    dispatch(
                      updateModProjectDetail({
                        ...ModProjectDetailData,
                        projectDescription: event.target.value,
                      })
                    )
                  }
                />
              </Grid>
            </Grid>
            {formData?.showEcoTerms && (
              <Grid item xs={12} md={8}>
                <Grid>
                  <label>
                    Does this modification represent a MATERIAL ADVERSE changes
                    in economics terms?
                  </label>
                  <span className="mandatory"> *</span>
                </Grid>

                <Grid>
                  <FormControl
                    sx={{ m: 1, minWidth: 120, margin: 0, width: "15%" }}
                    size="small"
                  >
                    <Select
                      error={submitErrorModel.data.modEcoterms}
                      value={ecoTerms}
                      disabled={!formData?.isFormEditable}
                      onChange={updateEcoTerms}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected === "" || selected === "undefined") {
                          return <em>Select</em>;
                        }
                        return selected;
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value={"yes"}>Yes</MenuItem>
                      <MenuItem value={"no"}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}
            {!formData?.showEcoTerms && (
              <Grid item md={8} className="grid-hide"></Grid>
            )}
            <Grid item xs={12} md={4}>
              <Grid>
                <label>Amount</label>
                <span className="mandatory"> *</span>
              </Grid>
              <Grid>
                <TextField
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                  }}
                  inputProps={{ min: 0, style: { textAlign: "right" } }}
                  fullWidth
                  size="small"
                  disabled={!formData?.isFormEditable}
                  error={submitErrorModel.data.modAmount}
                  className={classes.classes.input}
                  value={ModProjectDetailData?.authAmount}
                  onChange={updateAmount}
                ></TextField>
              </Grid>
            </Grid>
            <Grid item md={8} className="grid-hide"></Grid>
            {formData?.showTiaaOwnershipAmt && (
              <Grid item xs={12} md={4}>
                <Grid>
                  <label>TIAA Ownership Amount</label>
                </Grid>
                <Grid>
                  <TextField
                    InputProps={{
                      inputComponent: NumericFormatCustom as any,
                    }}
                    inputProps={{ min: 0, style: { textAlign: "right" } }}
                    fullWidth
                    size="small"
                    disabled={true}
                    className={classes.classes.input}
                    value={
                      ModProjectDetailData?.authAmount *
                      (formData?.tiaaOwnershipShare / 100)
                    }
                    error={false}
                  ></TextField>
                </Grid>
              </Grid>
            )}
          </Grid>
        </>
      }
    />
  );
};

export default ModProjectDetails;
