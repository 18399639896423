export const menuItemsAdminData = [
  {
    title: 'Dashboard',
    url: '/dashboard',
  },
  {
    title: 'Settings',
    submenu: [
      {
        title: 'Approved Capital Projects',
        url: '/settings/approvedCapitalProjects',
      },
      {
        title: 'Funds Email',
        url: '/settings/fundsemail',
      },
      {
        title: 'Authorization Verbiage',
        url: '/settings/verbiage',
      },
      {
        title: 'Authorization Types',
        url: '/settings/types',
      },
      {
        title: 'Approver Levels',
        url: '/settings/approverlevels',
      },
      {
        title: 'Approvers',
        url: '/settings/approvers',
      },
      {
        title: 'Funds',
        url: '/settings/funds',
      }
    ]
  },
  {
    title: 'Reports',
    submenu: [
      {
        title: 'Administration of Investments',
        url: '/reports/administrationOfInvestments',
      },
      {
        title: 'Investment Activity Summary',
        url: '/reports/investmentActivitySummary',
      }
    ]
  }
];

export const menuItemsManagerData = [
  {
    title: 'Dashboard',
    url: '/dashboard',
  },
  {
    title: 'Reports',
    submenu: [
      {
        title: 'Administration of Investments',
        url: '/reports/administrationOfInvestments',
      },
      {
        title: 'Investment Activity Summary',
        url: '/reports/investmentActivitySummary',
      }
    ]
  }
];

export const menuItemsExternalUserData = [
  {
    title: 'Dashboard',
    url: '/dashboard',
  }
];