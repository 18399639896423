import { Box, Button, Grid } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { administrationColumns } from "./administrationColumns";
import { useEffect, useState } from "react";

const AdministrationDataGrid = (props: any) => {

  const [rowData, setRowData] = useState<any>()
  
  useEffect(() => {
    setRowData(props.rowdata)
}, [props.rowdata])


  return (
    <>
      <Box className="dashboard-table-wrapper">
          <DataGridPro
            className="custom-data-grid"
            getRowId={(row) => row.formId}
            rows={rowData ?? []}
            columns={administrationColumns}
            columnHeaderHeight={48}
            headerFilterHeight={48}
            pageSizeOptions={[2, 10, 100]}
            rowHeight={40}
            disableRowSelectionOnClick
            pagination
            disableColumnMenu={true}
            autoHeight
            hideFooter={false}
            loading={props.loading}
          />
      </Box>
    </>
  );
};

export default AdministrationDataGrid;
