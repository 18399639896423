import { GridColTypeDef } from "@mui/x-data-grid-pro";

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0, 
  minimumFractionDigits: 0,
});

const usdPrice: GridColTypeDef = {
  type: 'number',
  width: 130,
  minWidth: 130,
  valueFormatter: (value) => currencyFormatter.format(value),
  cellClassName: 'font-tabular-nums',
};

export const capitalProjectsViewsColumn: any[] = [
  {
    field: "projectID",
    headerName: "Project ID",
    type: "string",
    flex: 0.25,
    headerAlign: "left",
    align: "left",
    minWidth: 120
  },
  {
    field: "name",
    headerName: "Project Name",
    type: "string",
    flex: 0.25,
    minWidth: 150
  },
  {
    field: "invID",
    headerName: "Investment ID",
    type: "string",
    flex: 0.25,
    minWidth: 120
  },
  {
    field: "invName",
    headerName: "Investment Name",
    type: "string",
    flex: 0.5,
    minWidth: 180
  },
  {
    field: "amountBudgeted",
    headerName: "Total Amount Budgeted",
    ...usdPrice,
  },
  {
    field: "amountSpent",
    headerName: "Total Amount Spent",
    ...usdPrice
  },
  {
    field: "budgetYear",
    headerName: "Budget Year",
    type: "string",
    flex: 0.25,
    align: "right",
    minWidth: 100
  },
  {
    field: "comments",
    headerName: "Comments",
    type: "string",
    flex: 0.25,
    minWidth: 150
  },
  {
    field: "isActive",
    headerName: "Is Active",
    type: "string",
    flex: 0.25,
    minWidth: 100
  },
  {
    field: "source",
    headerName: "Project Source",
    type: "string",
    flex: 0.25,
    minWidth: 130
  },
  {
    field: "createdBy",
    headerName: "Created By",
    type: "string",
    flex: 0.25,
    minWidth: 120
  },
  {
    field: "createdOn",
    headerName: "Creation Date",
    type: "string",
    flex: 0.25,
    minWidth: 130
  },
  {
    field: "updatedBy",
    headerName: "Updated By",
    type: "string",
    flex: 0.25,
    minWidth: 120
  },
  {
    field: "updatedAt",
    headerName: "Updated Date",
    type: "string",
    flex: 0.25,
    minWidth: 130
  }
];
