import { combineReducers, configureStore } from "@reduxjs/toolkit";
import budgetedDataSlice from "./slices/budgetedDataSlice";
import nonBudgetedDataSlice from "./slices/nonBudgetedDataSlice";
import fileUploadSlice from "./slices/fileUploadSlice";
import documentSlice from "./slices/documentSlice";
import createInvDataSlice from "./slices/createAuthSlice";
import currentUserReducer from "./slices/currentUserSlice";
import dashboardTabsReducer from "./slices/dashboardTabsSlice";
import auditButtonSlice from "./slices/auditButtonSlice";
import userContextDetailSlice from "./slices/userContextDetailSlice";
import dashboardDropdownSlice from "./slices/dashboardDropdownSlice";
import getFormDataSlice from "./slices/getFormDataSlice";
import commonslice from "./slices/commonslice";
import authDataSlice from "./slices/authDataSlice";
import propertyProjectDetailSlice from "./slices/propertyProjectDetailSlice";
import authorizationDataSlice from "./slices/authorizationDataSlice";
import submitFormErrorSlice from "./slices/submitFormErrorSlice";
import newFormDataSlice from "./slices/newFormDataSlice";
import proposalSlice from "./slices/proposalSlice";
import modProjectDetailsSlice from "./slices/modProjectDetailSlice";
import leaseProjectDetailSlice from "./slices/leaseProjectDetailSlice";
import jvAuthReasonSlice from "./slices/jvAuthReasonSlice";
import loaderSlice from "./slices/loaderSlice";
const rootReducer = combineReducers({
  budgetedData: budgetedDataSlice,
  newFormData: newFormDataSlice,
  nonBudgetedData: nonBudgetedDataSlice,
  fileUploadData: fileUploadSlice,
  documentData: documentSlice,
  invauthdetails: createInvDataSlice,
  newAuditData: auditButtonSlice,
  userContextDetail: userContextDetailSlice,
  currentUser: currentUserReducer,
  dashboardTab: dashboardTabsReducer,
  AuditData: createInvDataSlice,
  dashBoardDropdown: dashboardDropdownSlice,
  getFormData: getFormDataSlice,
  authData: authDataSlice,
  commonData: commonslice,
  propertyProjectDetail: propertyProjectDetailSlice,
  authorizationData: authorizationDataSlice,
  submitFormError: submitFormErrorSlice,
  modProjectDetailsData: modProjectDetailsSlice,
  proposaldata: proposalSlice,
  leaseProjectDetail: leaseProjectDetailSlice,
  jvAuthReason: jvAuthReasonSlice,
  loaderSlice : loaderSlice

});

export const makeStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof makeStore>
export type AppDispatch = AppStore['dispatch']
export const store = makeStore();
