import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAuthorizationRequest, } from "../../interfaces/Interfaces";
import { baseUrl } from "../../components/config/Config";
import { getApiCall } from "../../services/ApiHelper";

type InitialState = {
    createauthdata: FormData | null,
    error: string,
    loading: boolean
}

const initialState: InitialState = {
    createauthdata: null,
    error: '',
    loading: false
}

export const getinvdetails = createAsyncThunk("invauthdetails", async () => {
    try {
        var url = `${baseUrl}Dashboard/invauthdetails`
        const response = await getApiCall<FormData>(url);
        return response;
    } catch (error: any) {
        console.error('Error in POST request:', error);
        throw error;
    }
})

const createInvDataSlice = createSlice({
    name: 'invauthdetails',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getinvdetails.pending, (state) => {
            state.loading = true;
        })
            .addCase(getinvdetails.fulfilled, (state, action: PayloadAction<FormData | null>) => {
                state.loading = false;
                state.createauthdata = action.payload;
            })
            .addCase(getinvdetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'something went wrong';
            })
    }
});

export const getInvdetailsdata = (state: any) => state.invauthdetails.createauthdata

export default createInvDataSlice.reducer;
