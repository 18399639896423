import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField, Autocomplete, Radio, FormControlLabel, RadioGroup, FormGroup, Checkbox } from '@mui/material';
import { baseUrl } from '../../../components/config/Config';
import { postApiCall } from '../../../services/ApiHelper';
import { ToastMessage } from '../../../components/utils/ToastMessage';
import { capProjectValidate, toast } from '../../../interfaces/Interfaces';
import { capProjectResponse } from '../../../interfaces/Interfaces';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { makeStyles } from "tss-react/mui"
import { DateField, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const useStyles = makeStyles()((theme) => {
    return {
        input: {
            "& input[type=number]": {
                "-moz-appearance": "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
            },
            "& input[type=number]::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
            },
        },
    };
});

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
                prefix="$ "
            />
        );
    },
);

const CapitalProjectDialogBox = (props: any) => {
    const [toast, setToast] = useState(false);
    const [responseData, setResponseData] = useState<capProjectResponse>(
        {
            id: null,
            InvID: "",
            InvName: "",
            projectID: "",
            name: "",
            budgetYear: "",
            AmountBudgeted: "",
            AmountSpent: "",
            comments: "",
            isActive: true
        }
    )
    const [initailData, setInitialData] = useState <any>({       
        InvID: "",
        projectID: "",
        budgetYear: "",
    })
    const [validateData, setValidateData] = useState<capProjectValidate>(
        {
            InvID: false,
            projectID: false,
            name: false,
            budgetYear: false,
            totalAmountBudgeted: false,
            totalAmountSpent: false
        }
    )

    const [toastMessage, setToastMessage] = useState<toast>({
        message: "",
        severity: "",
    });

    useEffect(() => {
        setResponseData(props.updateRow)
        setInitialData({
            InvID: props.updateRow.InvID,
            projectID: props.updateRow.projectID,
            budgetYear: props.updateRow.budgetYear,
        })

    }, [props.updateRow]);

    const checkValidation = () => {
        setValidateData({
            InvID: responseData.InvID == "" ? true : false,
            projectID: responseData.projectID == "" ? true : false,
            name: responseData.name == "" ? true : false,
            budgetYear: responseData.budgetYear == "" ? true : false,
            totalAmountBudgeted: responseData.AmountBudgeted == "" ? true : false,
            totalAmountSpent: responseData.AmountSpent == "" ? true : false
        }
        )
        return responseData.InvID == "" || responseData.projectID == "" || responseData.name == "" || responseData.budgetYear == "" || responseData.AmountBudgeted == "" || responseData.AmountSpent == ""
    }
    const checkDuplicate = (rowData:any[]) =>{
        if(props.type === "Edit" && initailData.InvID === responseData.InvID && initailData.projectID === responseData.projectID && initailData.budgetYear === responseData.budgetYear){
            return false;
        }
        else{
        var check = rowData.some(item=> 
            item.invID === responseData.InvID?.split(" -")[0] && item.projectID === responseData.projectID && item.budgetYear === responseData.budgetYear
        );
        return check;
    }
    }

    const handleSubmitClick = async () => {
        var checkFormValidation = checkValidation()
        var checkForDuplicate = checkDuplicate(props.rowdata)
        if (!checkFormValidation && !checkForDuplicate) {
            handleSubmit()
        }
        else if(checkForDuplicate){
            setToast(true);
            setToastMessage({
                message: "Capital project already exists",
                severity: "error",
            });
            setTimeout(() => setToast(false), 4000);
        }
        else {
            setToast(true);
            setToastMessage({
                message: "Please fill in the required field(s)",
                severity: "error",
            });
            setTimeout(() => setToast(false), 4000);
        }
    }
    const handleSubmit = async () => {
        var url = `${baseUrl}capprojects/data`;
        try {
            const payload = {
                ...responseData,
                InvID: responseData.InvID?.split(" -")[0],
                InvName: responseData.InvID?.split("- ")[1],
                AmountBudgeted: parseFloat(responseData.AmountBudgeted),
                AmountSpent: parseFloat(responseData.AmountSpent)
        }

            const res = await postApiCall(url, payload).then(() => {
            setToast(true);
            setToastMessage({
                message: "Capital project added sucessfully",
                severity: "success",
            });
            setTimeout(() => setToast(false), 3000);
            setTimeout(() => props.setOpen(false), 3000);

        });
        props.submitClicked(true)

    } catch (error: any) {
        setToast(true);
        setToastMessage({
            message: `${error.response.data.description} (${error.response.data.code})`,
            severity: "error",
        });
        setTimeout(() => setToast(false), 4000);
    }
}


return (
    <>

        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="md"
            fullWidth
            className="common-dialog-box">
            <DialogTitle id ="alert-dialog-title" >
            {props.type} Capital Project
          </DialogTitle>
            <DialogContent style={{
                height: 'calc(60vh - 64px)',
                justifyContent: 'center',
            }}
                className='form-field-label'>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Grid >
                            <label>Investment ID</label><span className="mandatory"> * </span>
                        </Grid>
                        <Grid>
                            <Autocomplete
                                id="invId"

                                loading={props.invLoading}
                                options={!props.invLoading ? props?.adHocDropdown : []}
                                size="small"
                                fullWidth
                                value={responseData?.InvID}
                                onChange={(event, newInputValue) => {
                                    setResponseData({ ...responseData, InvID: newInputValue });
                                    setValidateData({ ...validateData, InvID: false })
                                }}
                                renderInput={(params) =>
                                    <TextField
                                        error={validateData.InvID}
                                        {...params} />}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid>
                            <label>Project ID</label><span className="mandatory"> * </span>
                        </Grid>
                        <Grid>
                            <TextField size='small'
                                fullWidth
                                error={validateData.projectID}
                                value={responseData?.projectID}
                                onChange={(event) => { setResponseData({ ...responseData, projectID: event.target.value }); setValidateData({ ...validateData, projectID: false }) }}> </TextField>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid>
                            <label>Project Name</label><span className="mandatory"> * </span>
                        </Grid>
                        <Grid>
                            <TextField size='small'
                                fullWidth
                                error={validateData.name}
                                value={responseData?.name}
                                onChange={(event) => { setResponseData({ ...responseData, name: event.target.value }); setValidateData({ ...validateData, name: false }) }}></TextField>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid >
                            <label>Budget Year</label><span className="mandatory"> * </span>
                        </Grid>
                        <Grid>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    views={['year']}
                                    value={dayjs(responseData?.budgetYear)}
                                    onChange={(newValue: dayjs.Dayjs | null) => {
                                        setResponseData({ ...responseData, budgetYear: newValue ? newValue.format('YYYY') : '' });
                                        setValidateData({ ...validateData, budgetYear: false });
                                    }}
                                    minDate={dayjs()}
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                            fullWidth: true,
                                            error: validateData.budgetYear,
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid>
                            <label >Total Amount Budgeted</label><span className="mandatory"> * </span>
                        </Grid>
                        <Grid>
                            <TextField size='small'
                                fullWidth
                                InputProps={{
                                    inputComponent: NumericFormatCustom as any
                                }}
                                inputProps={{ min: 0, style: { textAlign: 'right' } }}

                                error={validateData.totalAmountBudgeted}
                                value={responseData?.AmountBudgeted}
                                onChange={(event) => {
                                    setResponseData({ ...responseData, AmountBudgeted: event.target.value })
                                    setValidateData({ ...validateData, totalAmountBudgeted: false })
                                }}></TextField>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid>
                            <label>Total Amount Spent</label><span className="mandatory"> * </span>
                        </Grid>
                        <Grid>
                            <TextField
                                size='small'
                                fullWidth
                                InputProps={{
                                    inputComponent: NumericFormatCustom as any
                                }}
                                inputProps={{ min: 0, style: { textAlign: 'right' } }}
                                error={validateData.totalAmountSpent}
                                value={responseData?.AmountSpent}
                                onChange={(event) => { setResponseData({ ...responseData, AmountSpent: event.target.value }); setValidateData({ ...validateData, totalAmountSpent: false }) }}></TextField>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Grid>
                            <label>Comments</label>
                        </Grid>
                        <Grid>
                            <TextField size='small'
                                fullWidth={true}
                                minRows={3}
                                maxRows={7}
                                multiline
                                value={responseData?.comments}
                                onChange={(event) => setResponseData({ ...responseData, comments: event.target.value })}></TextField>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FormControlLabel
                            control={<Checkbox checked={responseData?.isActive}
                                onChange={(event) => setResponseData({ ...responseData, isActive: event.target.checked })} />}
                            label="Is Active"
                            sx={{
                                '& .MuiFormControlLabel-label': {
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    color: '#343434',
                                    lineHeight: '24px'

                                },
                            }} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>

                <Button onClick={() => { props.setOpen(false) }} color="primary" variant="outlined" className="btn-tertiary">
                    Cancel
                </Button>
                <Button onClick={() => { handleSubmitClick() }} className="btn-primary" variant="contained">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
        {toast && (
            <ToastMessage
                message={toastMessage.message}
                severity={toastMessage.severity}
            />
        )}
    </>

);
};
export default CapitalProjectDialogBox;