import AccordionTab from "../utils/AccordianTab";
import { Container, Grid, Input, InputLabel } from "@mui/material";
import TextField from "@mui/material/TextField";
import FileUpload from "./fileUpload/FileUpload";
import { LoadingButton } from "@mui/lab";
import { UploadedDocument } from "./fileUpload/UploadedDocument";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useState, useEffect } from "react";
import { getAllDocuments } from "../../redux/slices/documentSlice";
import { getFormData } from "../../redux/slices/getFormDataSlice";


const Attachments: React.FC = () => {
  const dispatch = useAppDispatch();
  const formData = useAppSelector(getFormData);
  let authorizationId: any = formData?.id;
  useEffect(() => {
    if(authorizationId){
      dispatch(getAllDocuments(authorizationId));
    }   
  }, [dispatch]);

  return (
    <AccordionTab
      title={"Attachments"}
      children={
        <div>
          {formData?.isFormEditable || formData?.allowDocumentUpload 
  ? <div className="select-tab">
            <FileUpload />
          </div> : null}
          <UploadedDocument
            authorizationId={authorizationId}
            document={[]}
            mode={"edit"}
          />
        </div>
      }
    ></AccordionTab>
  );
};
export default Attachments;
