import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
    data: any,
    error: string,
    loading: boolean
}
const initialState: InitialState =
{
    data: {} as any,
    error: '',
    loading: false
}

const fileUploadSlice=createSlice(
    {
        name: 'fileUpload',
        initialState,
        reducers:{
            filesTobeUploaded(state,action){
                state.data=action.payload;
            }
        }, 
        
    }
);
export const { filesTobeUploaded } = fileUploadSlice.actions;
export default fileUploadSlice.reducer;
